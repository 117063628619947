export const reduxStorageSessionSet = (key, payload) => {
  const actual = JSON.parse(sessionStorage.getItem('reduxStorage'));
  const result = Object.assign({}, actual, { [key]: payload });
  sessionStorage.setItem('reduxStorage', JSON.stringify(result));
}

export const reduxStorageLocalSet = (key, payload) => {
  const actual = JSON.parse(localStorage.getItem('reduxStorage'));
  const result = Object.assign({}, actual, { [key]: payload });
  localStorage.setItem('reduxStorage', JSON.stringify(result));
}

export const reduxStorageGet = key => {
  const actual = Object.assign({},
    JSON.parse(localStorage.getItem('reduxStorage')),
    JSON.parse(sessionStorage.getItem('reduxStorage'))
  );
  return actual[key];
}

export const reduxStorageRemove = key => {
  const actualLocal = JSON.parse(localStorage.getItem('reduxStorage'));
  if (actualLocal) {
    delete actualLocal[key];
    localStorage.setItem('reduxStorage', JSON.stringify(actualLocal));
  }

  const actualSession = JSON.parse(sessionStorage.getItem('reduxStorage'));
  if (actualSession) {
    delete actualSession[key];
    sessionStorage.setItem('reduxStorage', JSON.stringify(actualSession));
  }
}

export const getInitialState = () => {
  try {
    return Object.assign({},
      JSON.parse(localStorage.getItem('reduxStorage')),
      JSON.parse(sessionStorage.getItem('reduxStorage'))
    );
  } catch (error) {
    console.error('Bad storage. Cleaning up.');
    localStorage.clear();
    sessionStorage.clear();
    return {};
  }
}
