import React from 'react';
import { useMutation } from '@apollo/client';
import Button from 'common/components/Button';
import { Input } from 'common/components/Form';
import { closeModal } from 'dispatcher/modal';
import { toastSuccess, toastError } from 'dispatcher/toast';
import { 
  GET_COMPANY_TEAMS,
  CREATE_TEAM,
} from 'apollo/queries';

export const CreateModal = ({ companyId }) => {

  const [createTeam] = useMutation(CREATE_TEAM, {
    onError: () => toastError(`Error creando equipo`),
    onCompleted: () => toastSuccess(`Equipo creado con éxito`)
  });

  const handleCreateSubmit = (e) => {
    e.preventDefault();
    createTeam({
      variables: {
        data: {
          name: e.target.elements.name.value,
          companyId: companyId,
        }
      },
      refetchQueries: [{
        query: GET_COMPANY_TEAMS,
        variables: { companyId }
      }]
    });
    closeModal();
  };
  return <form className="flex flex-column items-center justify-center pa8 bg-white" onSubmit={handleCreateSubmit}>
    <Input 
      id="name"
      type="text"
      label={t('Nombre')}
      required
      placeholder="nombre del equipo o restaurante"
    />
    <Button
      type="submit"
      className="center mt8"
    >
      {t('Crear equipo')}
    </Button>
  </form>
}