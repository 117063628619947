import React from 'react';
import { useMutation } from '@apollo/client';
import Button from 'common/components/Button';
import { closeModal } from 'dispatcher/modal';
import { toastSuccess, toastError } from 'dispatcher/toast';
import { 
  GET_COMPANY_TEAMS,
  DELETE_TEAM,
} from 'apollo/queries';

export const DeleteModal = ({ team }) => {

  const [deleteTeam] = useMutation(DELETE_TEAM, {
    onError: () => toastError(`Error eliminando equipo`),
    onCompleted: () => toastSuccess(`Equipo eliminado con éxito`)
  });

  const { id, name, company } = team;

  return <div id={id} className="flex flex-column items-center justify-center pa8">
    <h3 className="fw5 mt8">{`Estas seguro de que quieres eliminar el equipo ${name}?`}</h3>
    <div className="flex items-center justify-evenly w-100 mt8">
      <Button
        type="button"
        className="center btn-danger"
        onClick={(e) => {
          e.preventDefault()
          deleteTeam({
            variables: { 
              id
            },
            refetchQueries: [{
              query: GET_COMPANY_TEAMS,
              variables: { companyId: company.id }
            }]
          })
          closeModal();
          window.history.back()
        }}
      >
        Delete
      </Button>
      <Button
        secondary
        type="button"
        className="center"
        onClick={() => closeModal()}
      >
        Cancel
      </Button>
    </div>
  </div>
}