import { withEffects } from 'redux/refractSideEffects';
import { map, pipe } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import { ERROR_PAGE } from 'redux/actions';
import Unauthorized from './Unauthorized';
import Unauthenticated from './Unauthenticated';
import InternalServerError from './InternalServerError';
import NotFound from './NotFound';
import ErrorPageProvider from './ErrorPageProvider';

export const ERROR_CODES = {
  INTERNAL_SERVER_ERROR: 500,
  NOT_FOUND: 404,
  UNAUTHORIZED: 403,
  UNAUTHENTICATED: 401,
}

export const ERROR_PAGES = [
  {
    code: ERROR_CODES.INTERNAL_SERVER_ERROR,
    component: InternalServerError
  },{
    code: ERROR_CODES.NOT_FOUND,
    component: NotFound
  },{
    code: ERROR_CODES.UNAUTHORIZED,
    component: Unauthorized
  }, {
    code: ERROR_CODES.UNAUTHENTICATED,
    component: Unauthenticated,
  },
]

const aperture = ({ store }) => component => {
  return pipesToProps(
    pipe(
      store.observe(ERROR_PAGE),
      map(({ payload }) => {
        return { ...payload }
      })
    ),
  );
}

const handler = () => () => null;

export default Wrapped => withEffects(handler)(aperture)(ErrorPageProvider(Wrapped), 'ErrorPageProvider');
