import { gql } from '@apollo/client';

export const GET_COMPANIES = gql`
  query getCompanies {
    getCompanies {
      id,
      name,
      address,
      vatNumber,
      phone
    }
  }
`;

export const GET_COMPANY = gql`
  query getCompany($id: ID!) {
    getCompany(id: $id) {
      id,
      name,
      address,
      vatNumber,
      phone
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($id: ID!, $data: UpdateCompanyInput) {
    updateCompany(id: $id, data: $data) {
      id,
      name,
      address,
      vatNumber,
      phone
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id)
  }
`;

export const ADD_COMPANY = gql`
  mutation createCompany($data: CompanyInput) {
    createCompany(data: $data) {
      id,
      name,
      address,
      vatNumber,
      phone
    }
  }
`;

export const GET_COMPANY_TEAMS = gql`
  query getCompanyTeams($companyId: ID!) {
    getCompanyTeams(companyId: $companyId) {
      id,
      name,
      company {
        id,
        name
      }
      members {
        id
      }
    }
  }
`;

export const GET_TEAMS = gql`
  query getTeams {
    getTeams {
      id,
      name,
      company {
        id,
        name
      }
      members {
        id
        username
        email
      }
    }
  }
`;

export const GET_TEAM = gql`
  query getTeam($id: ID!) {
    getTeam(id: $id) {
      id,
      name,
      company {
        id
      }
      members {
        id
        username
        email
      }
      invites {
        owner {
          username
        }
        id
        hash
        email
        status
        expirationDate
      }
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation createTeam($data: CompanyTeamInput) {
    createTeam(data: $data) {
      id
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(id: $id)
  }
`;

export const UPDATE_TEAM = gql`
  mutation updateTeam($id: ID!, $data: UpdateCompanyTeamInput) {
    updateTeam(id: $id, data: $data) {
      id,
      name,
    }
  }
`;

export const GET_COMPANY_USERS = gql`
  query getCompanyUsers($id: ID!) {
    getCompanyUsers(id: $id) {
      id,
      username,
      email
    }
  }
`;

export const INVITE_TEAM_MEMBERS = gql`
  mutation inviteMembers($teamId: ID!, $emails: [String]) {
    inviteMembers(teamId: $teamId, emails: $emails) {
      id,
      name,
      invites {
        owner {
          username
        },
        email,
        status,
        expirationDate
      }
    }
  }
`;

export const ACCEPT_INVITE = gql`
  mutation acceptInvite($teamId: ID!, $inviteHash: String!, $data: AcceptInviteInput) {
    acceptInvite(teamId: $teamId, inviteHash: $inviteHash, data: $data) {
      type
      accessToken
      refreshToken {
        hash
        ip
        userAgent
      }
      ttl
    }
  }
`;

export const REVOKE_INVITE = gql`
  mutation revokeInvite($teamId: ID!, $inviteHash: String!) {
    revokeInvite(teamId: $teamId, inviteHash: $inviteHash) {
      owner {
        username
      },
      email
    }
  }
`;

export const DELETE_INVITE = gql`
  mutation deleteInvite($teamId: ID!, $inviteHash: String!) {
    deleteInvite(teamId: $teamId, inviteHash: $inviteHash) {
      name
    }
  }
`;

export const REMOVE_TEAM_MEMBER = gql`
  mutation removeTeamMember($teamId: ID!, $userId: ID!) {
    removeTeamMember(teamId: $teamId, userId: $userId) {
      name
    }
  }
`;

export const RESEND_INVITE = gql`
  mutation resendInvite($teamId: ID!, $inviteHash: String!) {
    resendInvite(teamId: $teamId, inviteHash: $inviteHash) {
      owner {
        username
      },
      email
    }
  }
`;

