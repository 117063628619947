import { gql } from '@apollo/client';

export const GET_ROLES = gql`
  query {
    getRoles {
      id,
      name,
      permissions {
        module,
        kind,
        action,
        name
      }
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query {
    getPermissions {
      permissions {
        name,
        kind,
        action,
        module
      },
      hierarchy {
        name,
        kinds {
          name
          actions {
            name
          }
        }
      }
    }
  }
`;

export const ADD_ROLE = gql`
  mutation addRole($input: RoleInput) {
    addRole(input: $input) {
      id,
      name,
      permissions {
        module,
        kind,
        action,
        name
      }
    }
  }
`

export const UPDATE_ROLE = gql`
  mutation updateRole($id: ID!, $input: RoleInput) {
    updateRole(id: $id, input: $input) {
      id,
      name,
      permissions {
        module,
        kind,
        action,
        name
      }
    }
  }
`