import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import AccountAvatar from 'common/components/AccountAvatar';

export default function ProfileMain({ profile }) {
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div>
              <AccountAvatar
                  className="w3 h3 mh2"
                  picture={profile.picture}
                  firstName={profile.username}
                  lastName=''
              />
            </div>
            <div className="profile__data flex flex-column justify-center items-start">
              <p className="profile__name">{profile.username}</p>
              <p className="profile__contact">{profile.email}</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}
