import React from 'react';
import { useQuery } from '@apollo/client';
import { Select } from 'common/components/Form';
import { GET_TEAMS } from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import './TeamSelector.css';

export default function TeamSelector ({ defaultValue, onChange, required = false }) {
  const { loading, error, data } = useQuery(GET_TEAMS);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { getTeams: teams } = data;
 
  return (
    <Select
      id="teamId"
      placeholder="Select a team"
      label="Team"
      defaultValue={defaultValue}
      onChange={onChange}
      required={required}
    >
      {teams && teams.map((team, index) =>
        <option key={index} value={team.id}>{team.name} - {team.company.name}</option>
      )}
    </Select>
  )
}