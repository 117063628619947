import React from 'react';

import { capitalize } from 'utils';

const currentYear = new Date().getFullYear();
const classes = {
  select: 'Select DayPicker-select'
}

export default ({ date, onChange, yearRange = {} }) => {
  const { from = currentYear, to = currentYear } = yearRange;
  const numYears = Math.abs(to - from) + 1
  const newDate = new Date(date);
  return <div className="dtca center mb3" style={{ width: '170px'}}>
    <div className="flex">
      <select
        className={`${classes.select} w-auto`}
        style={{ textAlignLast: 'center' }}
        value={date.getMonth()}
        onChange={e => {
          newDate.setMonth(e.target.value)
          setTimeout(() => onChange(newDate), 0)
        }}
      >
        {i18n._months_long.map((month, i) =>
          <option key={i} value={i}>{capitalize(month)}</option>
        )}
      </select>
      <select
        className={classes.select}
        value={date.getFullYear()}
        onChange={e => {
          newDate.setFullYear(e.target.value)
          onChange(newDate)
        }}
      >
        {Array.from({ length: numYears }).map((_, i) => i + from).map(year =>
          <option key={year} value={year}>{year}</option>
        )}
      </select>
    </div>
  </div>
}
