import React, { useState, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import CustomDataTable from 'common/components/CustomDataTable';
import IconButton from 'common/components/CustomDataTable/IconButton';
import { InputSearch } from 'common/components/Form';
import Button from 'common/components/Button';
import {
  GET_TEAM,
  REVOKE_INVITE,
  DELETE_INVITE,
  RESEND_INVITE,
} from 'apollo/queries';
import { capitalize } from 'utils';
import { numericDateFormat, dateToLocaleString } from 'utils/date';
import CloseIcon from 'mdi-react/CloseIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import { toastSuccess, toastError } from 'dispatcher/toast';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="flex items-center">
    <InputSearch
      id="search-input"
      className="SearchInput br2 ml5"
      defaultValue={ filterText }
      searchAction={(e) => e.value ? onFilter(e.value) : onClear()}
      placeholder={t('Search...')} 
      actionDelayOnChange={400}
      actionMinValueLength={1}
      autoFocus={true}
    />
  </div>
);

export default function Invites({ teamId, invites, openInviteMembersModal }) {

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [revokeInvite] = useMutation(REVOKE_INVITE, {
    onCompleted: ({ revokeInvite }) => {
      toastSuccess(t('Invitación revocada correctamente'));
    },
    onError: (err) => {
      toastError(t('No se puedo revocar la invitación'))
    }
  });
  const [deleteInvite] = useMutation(DELETE_INVITE, {
    onCompleted: ({ deleteInvite }) => {
      toastSuccess(t('Invitación revocada correctamente'));
    },
    onError: (err) => {
      toastError(t('No se puedo revocar la invitación'))
    }
  });
  const [resendInvite] = useMutation(RESEND_INVITE, {
    onCompleted: ({ resendInvite }) => {
      toastSuccess(t('Invitación reenviada correctamente'));
    },
    onError: (err) => {
      toastError(t('No se puedo revocar la invitación'))
    }
  });

  const InviteMembersComponent = () => {
    return (
      <div className="mr-auto">
        <Button
          primary
          type="submit"
          onClick={openInviteMembersModal}
        >
          + Add User
        </Button>
      </div>
    )
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <React.Fragment>
      <InviteMembersComponent />
      <FilterComponent onFilter={e => setFilterText(e)} onClear={handleClear} filterText={filterText} />
    </React.Fragment>;
  }, [filterText, resetPaginationToggle]);

  const filteredItems = invites.filter(({ email }) => {
    return email && email.toLowerCase().includes(filterText.toLowerCase())
  });

  const columns = [
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      grow: 2
    },
    {
      name: 'Status',
      sortable: true,
      grow: 2,
      cell: (row) => {
        const status = new Date(row.expirationDate).getTime() < new Date().getTime() ? 'EXPIRED' : row.status;
        let color = '';
        switch(status) {
          case 'ACCEPTED':
            color = 'bg-light-success';
            break;
          case 'REVOKED':
            color = 'bg-light-error';
            break;
          case 'EXPIRED':
            color = 'bg-light-warning';
            break;
          case 'PENDING':
          default:
            color = 'bg-cherub'
            break;
        }
        return <div className={`pa3 br4 ${color}`}>{capitalize(status.toLowerCase())}</div>
      }
    },
    {
      name: 'Expiration',
      sortable: true,
      grow: 2,
      cell: (row) => {
        return dateToLocaleString(row.expirationDate, numericDateFormat)
      }
    },
    {
      name: 'Onwer',
      selector: 'owner.username',
      sortable: true,
      grow: 2
    },
    {
      grow: .5,
      center: true,
      cell: (row) => {
        return <div className="flex items-center justify-evenly w-100">
          {row.status === 'PENDING' && <IconButton
            title="Resend Invite"
            icon={<RefreshIcon />}
            onClick={(e) => {
              e.preventDefault()
              return resendInvite({
                variables: {
                  teamId: teamId,
                  inviteHash: row.hash,
                },
                refetchQueries: [
                  {
                    query: GET_TEAM,
                    variables: { id: teamId }
                  }
                ]
              })
            }}
          />}
          {row.status === 'PENDING' && <IconButton
            title="Revoke Invite"
            onClick={(e) => {
              e.preventDefault();
              return revokeInvite({
                variables: {
                  teamId: teamId,
                  inviteHash: row.hash,
                },
                refetchQueries: [
                  {
                    query: GET_TEAM,
                    variables: { id: teamId }
                  }
                ]
              })
            }}
            icon={<CloseIcon />}
          />}
          {row.status === 'REVOKED' && <IconButton
            title="Delete Invite"
            onClick={(e) => {
              e.preventDefault();
              return deleteInvite({
                variables: {
                  teamId: teamId,
                  inviteHash: row.hash,
                },
                refetchQueries: [
                  {
                    query: GET_TEAM,
                    variables: { id: teamId }
                  }
                ]
              })
            }}
            icon={<TrashIcon />}
          />}
        </div>
      },
      right: true
    }
  ];

  return (
    <div className="Companies dataTable">
      <CustomDataTable
        columns={columns}
        data={filteredItems}
        resetPaginationToggle={resetPaginationToggle}
        subHeaderComponentMemo={subHeaderComponentMemo}
        subHeader
        fixedHeader={true}
        fixedHeaderScrollHeight="calc(100vh - 17rem - 12px)"
      />
    </div>
  );
}