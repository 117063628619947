import React from 'react';

export default function Avatar({ picture, firstName, lastName, className }) {
  return (
    <div id="user-avatar" className={className}>
      { picture ? 
        <img className="of-cover br-100" src={picture} alt="user_pic"/> 
        : 
        <div className="flex items-center of-cover h-100 br-100 bg-concrete ba b--cherub">
          <span className="ml-auto mr-auto f5 b">{firstName.substring(0,1).toUpperCase()}{lastName.substring(0,1).toUpperCase()}</span>
        </div> 
      }
    </div>
  )
}
