import queryString from 'query-string';

export const parseSearchQuery = (search) => queryString.parse(search);

export const getSortDirection = (direction) => {
  switch(direction) {
    case 'asc':
      return 1;
    case 'desc':
      return -1
    default: 
      return null;
  }
}

export const pushHistory = (route, currentPagination, currentSort) => {

  const search = Object.assign({}, queryString.parse(window.location?.search), {
    ...(currentPagination.page && { page: currentPagination.page }),
    ...(currentPagination.rowsPerPage && { rowsPerPage: currentPagination.rowsPerPage }),
    ...(currentSort.key && { sort: currentSort.key }),
    ...(currentSort.direction && { direction: currentSort.direction }),
  });
  
  route.history.push(`${window.location.pathname}?${queryString.stringify(search)}`);
}