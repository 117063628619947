const options = {
  // order and from where user language should be detected
  order: ['path', /*'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'subdomain'*/],

  // keys or params to lookup language from
  // lookupQuerystring: 'lng',
  // lookupCookie: 'i18next',
  // lookupLocalStorage: 'i18nextLng',
  // lookupFromPathIndex: 0,
  // lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: [],
  // caches: ['localStorage', 'cookie'],
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  // cookieMinutes: 10,
  // cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  // htmlTag: document.documentElement
}

export default options;

// docs: https://github.com/i18next/i18next-browser-languageDetector