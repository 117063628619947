import React from 'react';
import { hydrate, render } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'common/styles/scss/app.scss';
import './css/tachyons.scss';
import './css/overrides/classes.scss';
import './css/overrides/colors.scss';
import App from 'components/App';
import { client as ApolloClient } from 'apollo';
import SetLayout from 'hocs/SetLayout';

const rootElement = document.getElementById('root');

const AppWithLayout = SetLayout(App);

const ContextApp = () => {
  return (
    <React.Fragment>
      <ApolloProvider client={ApolloClient}>
        <AppWithLayout />
      </ApolloProvider>
    </React.Fragment>
  )
}

if (rootElement.hasChildNodes()) {
  hydrate(<ContextApp />, rootElement);
} else {
  render(<ContextApp />, rootElement);
}

serviceWorker.unregister();
