import { map, pipe, merge } from 'callbag';
import { fromEvent } from 'dispatcher';
import { SESSION } from 'redux/actions';

export const sessionToProps = (component, store) =>
  merge(
    pipe(
      merge(component.mount, store.observe(SESSION)),
      map(() => store.getState().user),
      map(({ session }) => {
        return ({ session })
      })
    ),
    pipe(
      fromEvent('LANGUAGE_CHANGED'),
      map(x => ({ language: x }))
    )
  )

export const getSession = (component, store) => {
  return merge(
    pipe(
      merge(component.mount, store.observe(SESSION)),
      map(() => store.getState().user),
      map(({ session }) => {
        return ({ session })
      })
    ),
    pipe(
      fromEvent('LANGUAGE_CHANGED'),
      map(x => ({ language: x }))
    )
  )
}