/*
Generates API actions with the listed types. Should be called like:
export const { actionTypes: API_TEST, actionCreators: test } = buildApiActions('TEST');

API_ prefix is required for the reducer to be autogenerated. See: redux/reducers/index.js
*/

export default function buildApiActions(baseName) {
  const types = {};
  [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
    'CLEAR',
  ].forEach(type => types[type] = `${baseName}_${type}`);

  return {
    actionTypes: types,
    actionCreators: {
      request: args => ({ type: types.REQUEST, ...args }),
      success: args => ({ type: types.SUCCESS, payload: args }),
      failure: args => ({ type: types.FAILURE, payload: args }),
      clear: args => ({ type: types.CLEAR, payload: args }),
    }
  };
}
