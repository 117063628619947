import React from 'react';
import Label from 'common/components/Form/Label';
import StatusMessage from 'common/components/Form/StatusMessage';
import './Input.css';


const Input = React.forwardRef(
  function Input(props, ref) {
    const hasError = props.error && props.error.status ? 'b--error' : '';
    const isSuccess = props.success && props.success.status ? 'b--success' : '';

    const classes = {
      input: `Input outline-0 flex-grow-1 ${hasError} ${isSuccess} ${props.className || ''} ${props.actionButton ? 'br--left' : ''}`,
      actionButton: props.actionButton ? `${props.actionButton.props.className || ''} br--right` : ''
    }

    return !props.id ? 'Please set and ID for this input' : (
      <div className={`Form-group flex-no-overgrowth ${props.wrapperClass || '' }`}>
        {props.label && <Label id={props.id} label={props.label === "_empty" ? '' : props.label} className={props.labelClass}/>}
        <div className="flex">
          <input
            ref={ref}
            id={props.id}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            value={props.value}
            className={classes.input}
            autoComplete={props.autoComplete || 'off'}
            autoFocus={props.autoFocus}
            type={props.type}
            required={props.required}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
            min={props.min}
            max={props.max}
            minLength={props.minLength}
            maxLength={props.maxLength}
            disabled={props.disabled}
            accept={props.accept}
            data-type={props['data-type']} // This should be removed when searchplacelist is refactored or look a way to pass data- props
            data-selected={props['data-selected']} // This should be removed when searchplacelist is refactored or look a way to pass data- props
            step={props.step}
          />
          {props.actionButton &&
            React.cloneElement(props.actionButton, {
              className: classes.actionButton,
              error: hasError,
              success: isSuccess
            })
          }
        </div>
        <StatusMessage
          isSuccess={isSuccess}
          hasError={hasError}
          error={props.error}
          success={props.success}
          id={props.id}
        />
      </div>
    );
  }
);

export default Input;
