export const LocalStorage = {
  set:(name, body) => {
    const payload = JSON.stringify(body);
    localStorage.setItem(name, payload);
  },
  get: (name) => {
    const payload = localStorage.getItem(name);
    if (!payload) {
      return null;
    }
    try {
      return JSON.parse(payload);
    } catch (e) {
      return null;
    }
  },
  remove: (name) => {
    localStorage.removeItem(name);
  },
  isAvailable: () => {
    return typeof localStorage !== 'undefined';
  }
}
