import { withEffects } from 'redux/refractSideEffects';
import { pipe, map, merge } from 'callbag';
import { getSession } from 'pipes/session';
import { getProfile } from 'pipes/profile';
import { onRouteChange } from 'pipes/route';
import { pipesToProps } from 'pipes/toProps'
import { errorPage as setErrorPage, CURRENT_ROUTE } from 'redux/actions';
import SecuredWithProfile from './securedWithProfile';

const aperture = ({ store }) => component => {
  const dispatchErrorPage = ({ code, message}) => {
    store.dispatch(setErrorPage({
      code,
      error: {
        message,
      }
    }))
  }
  return pipesToProps(
      getSession(component, store),
      getProfile(component, store),
      pipe(
        component.mount,
        map(() => ({
          dispatchErrorPage,
        }))
      ),
      pipe(
        merge(
          onRouteChange(component),
          store.observe(CURRENT_ROUTE),
        ),
        map(route => ({ route })),
      ),
    )
}
const handler = () => () => null

export default Wrapped => withEffects(handler)(aperture)(SecuredWithProfile(Wrapped), 'securedWithProfile');
