import pathToRegexp from 'path-to-regexp';
import queryString from 'query-string';

export * from 'routes/nav/_index';
export * from './system';

const createRoute = (route, params, search, lng, preappend) => {
  const lngStr = lng === process.env.REACT_APP_i18nFallbackLng ? '' : `/${lng}`;
  const path = pathToRegexp.compile(route)(params, { encode: x => x });
  return `${preappend}${lngStr}${path === '/' ? '' : path}${search}`;
}

export default (route = '/', { params, query, lng: _lng, preappend = '' } = {}) => {
  const lng = _lng || i18n.language || process.env.REACT_APP_i18nFallbackLng;
  try {
    if (!route.paths) {
      throw new Error('Missing paths');
    }
    if (lng === 'ALL') {
      return Object.keys(route.paths).map(lng => {
        const lngStr = lng === process.env.REACT_APP_i18nFallbackLng ? '' : `/${lng}`;
        return `${preappend}${lngStr}${route.paths[lng] === '/' ? '' : route.paths[lng]}`;
      })
    } else {
      let search = queryString.stringify(query, { encode: false, arrayFormat: 'comma' });
      search = search ? `?${search}` : '';
      const validRoute = route.paths[lng] || route.paths[process.env.REACT_APP_i18nFallbackLng];
      return createRoute(validRoute, params, search, lng, preappend) || '/';
    }
  } catch (error) {
    console.error ('Failed nav route >> ', route, params, lng, error);
    return '/';
  }
}
