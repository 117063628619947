import { map, pipe, filter, merge, takeUntil} from 'callbag';
import { fromEvent } from 'dispatcher';
import { latestRoute } from 'dispatcher/route';

export const onRouteChange = component =>
  merge(
    pipe(
      component.mount,
      takeUntil(component.unmount),
      map(() => latestRoute),
      filter(route => route),
    ),
    fromEvent('ROUTE_CHANGED'),
  )
