
import React from 'react';
import * as routes from 'routes/nav';
import getRoute from 'routes/nav';
import { profileCanSeeRoute } from 'utils';
import './Homepage.css';

export default function Homepage({ profile, title }) {
  if(!profile) return null;
  return <div className="Homepage-container">
    <h2>{title}</h2>
    <div className="w-100 flex items-start justify-start flex-gap pa8">
      {
        Object.keys(routes).map((route) => {
          const showLink = profileCanSeeRoute({ profile, routes, route }) && route !== 'root';
          if(
            !showLink ||
            routes[route].hideFromNav ||
            route === "default" ||
            route[0] === '_'
          ) return null;
          const contextRoute = routes[route];
          return <a key={contextRoute.title} className="ph8 br2 bg-white black" href={getRoute(contextRoute)}>
            <div className="pv7">
              <div className="f4">{t(`${contextRoute.title}`)}</div>
              <span>{t(`${contextRoute.description}`)}</span>
            </div>
          </a>
        })
      }
    </div>
  </div>
}
