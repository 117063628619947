import { gql } from '@apollo/client';

export const GET_RECIPE_TYPES = gql`
  query {
    getRecipeTypes {
      id,
      name,
    }
  }
`;

export const UPDATE_RECIPE_TYPE = gql`
  mutation updateRecipeType($id: ID!, $input: RecipeTypeInput) {
    updateRecipeType(id: $id, input: $input) {
      id,
      name,
    }
  }
`;

export const DELETE_RECIPE_TYPE = gql`
  mutation deleteRecipeType($id: ID!) {
    deleteRecipeType(id: $id)
  }
`;

export const ADD_RECIPE_TYPE = gql`
  mutation addRecipeType($input: RecipeTypeInput) {
    addRecipeType(input: $input) {
      id,
      name,
    }
  }
`;