import { CUSTOMIZATION } from 'redux/actions';

export function customization (state = {}, action) {
  switch (action.type) {
    case CUSTOMIZATION:
      return Object.assign({}, state, {
        ...action.payload,
      });
    default: return state
  }
}
