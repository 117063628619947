import React, { useState } from 'react';

import DataTable from 'react-data-table-component';
import { defaultRowsPerPage } from 'config';
import { pushHistory, getSortDirection, parseSearchQuery } from './utils';
import './CustomDataTable.scss';

export default function CustomDataTable ({ 
  title,
  columns,
  data,
  resetPaginationToggle,
  subHeaderComponentMemo,
  onRowClicked,
  selectableRows,
  paginationServer,
  paginationTotalRows,
  route,
  sortServer,
  noHeader,
  subHeader,
  progressComponent,
  progressPending,
  fixedHeader,
  fixedHeaderScrollHeight
 }) {

  const search = parseSearchQuery(window?.location?.search);
  const [currentPagination, setCurrentPagination] = useState({
    page: parseInt(search?.page) || 1,
    rowsPerPage: parseInt(search?.rowsPerPage) || defaultRowsPerPage,
  });
  const [currentSort, setCurrentSort] = useState({
    key: search?.sort,
    direction: search?.direction,
  });
  const handleRowClicked = (row) => onRowClicked(row)
  const defaultSortAsc = parseInt(currentSort.direction) === 1 || currentSort.direction === undefined ? false : true;

  return (
    <DataTable
        title={title}
        columns={columns}
        data={data}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        paginationDefaultPage={currentPagination.page}
        paginationPerPage={currentPagination.rowsPerPage}
        persistTableHead
        data-tag="allowRowEvents"
        paginationRowsPerPageOptions={[10, 50, 100]}
        defaultSortAsc={defaultSortAsc}
        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
          const pagination = {
            page: currentPage,
            rowsPerPage: currentRowsPerPage,
          }
          setCurrentPagination(pagination)
          pushHistory(route, pagination, currentSort)
        }}
        onChangePage={(page, totalRows) => {
          const pagination = {
            page: page,
            rowsPerPage: currentPagination.rowsPerPage,
          }
          setCurrentPagination(pagination)
          pushHistory(route, pagination, currentSort)
        }}
        {...(noHeader && { noHeader })}
        {...(subHeader && { subHeader })}
        {...(subHeaderComponentMemo && { subHeaderComponent: subHeaderComponentMemo })}
        {...(progressPending && { progressPending })}
        {...(progressComponent && { progressComponent })}
        {...(!!paginationServer && { paginationServer })}
        {...(!!paginationTotalRows && { paginationTotalRows })}
        {...(selectableRows && { selectableRows: selectableRows})}
        {...(onRowClicked && {onRowClicked: handleRowClicked})}
        {...(sortServer && { sortServer })}
        {...(sortServer && { onSort:(column, sortDirection) => {
          const sort = {
            key: column.selector,
            direction: getSortDirection(sortDirection)
          }
          setCurrentSort(sort);
          pushHistory(route, currentPagination, sort);
        }})}
        {...(fixedHeader && { fixedHeader })}
        {...(fixedHeaderScrollHeight && { fixedHeaderScrollHeight })}
      />
  );
}
