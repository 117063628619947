import React from 'react';
import { useQuery } from '@apollo/client';
import { Select } from 'common/components/Form';
import { GET_RECIPE_TYPES } from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import './RecipeTypeSelector.css';

export default function RecipeTypeSelector ({ defaultValue, onChange, required = false }) {
  const { loading, error, data } = useQuery(GET_RECIPE_TYPES);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { getRecipeTypes: types } = data;
  return <Select 
    id="type"
    placeholder="Tipo de la receta"
    label="Tipo de la receta"
    defaultValue={defaultValue}
    onChange={onChange}
    required={required}
  >
    {types && types.map((type, index) =>
      <option key={index} value={type.id}>{type.name}</option>
    )}
  </Select>
}