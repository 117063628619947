const user_agent = navigator.userAgent;
const vendor = navigator.vendor || ''; // vendor is undefined for at least IE9
const opera = window.opera;

// This utils set is directly imported from mixpanel-browser package in npm
// Fallback license to https://github.com/mixpanel/mixpanel-js/blob/master/LICENSE
// Source: https://github.com/mixpanel/mixpanel-js/blob/master/src/utils.js

const browser = () => {
  if (opera || user_agent.includes(' OPR/')) {
    if (user_agent.includes('Mini')) {
      return 'Opera Mini';
    }
    return 'Opera';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
    return 'BlackBerry';
  } else if (user_agent.includes('IEMobile') || user_agent.includes('WPDesktop')) {
    return 'Internet Explorer Mobile';
  } else if (user_agent.includes('Edge')) {
    return 'Microsoft Edge';
  } else if (user_agent.includes('FBIOS')) {
    return 'Facebook Mobile';
  } else if (user_agent.includes('Chrome')) {
    return 'Chrome';
  } else if (user_agent.includes('CriOS')) {
    return 'Chrome iOS';
  } else if (user_agent.includes('UCWEB') || user_agent.includes('UCBrowser')) {
    return 'UC Browser';
  } else if (user_agent.includes('FxiOS')) {
    return 'Firefox iOS';
  } else if (vendor.includes('Apple')) {
    if (user_agent.includes('Mobile')) {
      return 'Mobile Safari';
    }
    return 'Safari';
  } else if (user_agent.includes('Android')) {
    return 'Android Mobile';
  } else if (user_agent.includes('Konqueror')) {
    return 'Konqueror';
  } else if (user_agent.includes('Firefox')) {
    return 'Firefox';
  } else if (user_agent.includes('MSIE') || user_agent.includes('Trident/')) {
    return 'Internet Explorer';
  } else if (user_agent.includes('Gecko')) {
    return 'Mozilla';
  } else {
    return '';
  }
};

const browserVersion = () => {  
  var versionRegexs = {
    'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
    'Microsoft Edge': /Edge\/(\d+(\.\d+)?)/,
    'Chrome': /Chrome\/(\d+(\.\d+)?)/,
    'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
    'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    'Safari': /Version\/(\d+(\.\d+)?)/,
    'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
    'Opera': /(Opera|OPR)\/(\d+(\.\d+)?)/,
    'Firefox': /Firefox\/(\d+(\.\d+)?)/,
    'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
    'Konqueror': /Konqueror:(\d+(\.\d+)?)/,
    'BlackBerry': /BlackBerry (\d+(\.\d+)?)/,
    'Android Mobile': /android\s(\d+(\.\d+)?)/,
    'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
    'Mozilla': /rv:(\d+(\.\d+)?)/
  };
  var regex = versionRegexs[browser()];
  if (regex === undefined) {
    return null;
  }
  var matches = user_agent.match(regex);
  if (!matches) {
    return null;
  }
  return parseFloat(matches[matches.length - 2]);
};

const os = () => {
  var a = user_agent;
  if (/Windows/i.test(a)) {
    if (/Phone/.test(a) || /WPDesktop/.test(a)) {
      return 'Windows Phone';
    }
    return 'Windows';
  } else if (/(iPhone|iPad|iPod)/.test(a)) {
    return 'iOS';
  } else if (/Android/.test(a)) {
    return 'Android';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
    return 'BlackBerry';
  } else if (/Mac/i.test(a)) {
    return 'Mac OS X';
  } else if (/Linux/.test(a)) {
    return 'Linux';
  } else if (/CrOS/.test(a)) {
    return 'Chrome OS';
  } else {
    return '';
  }
};

const device = () => {
  if (/Windows Phone/i.test(user_agent) || /WPDesktop/.test(user_agent)) {
    return 'Windows Phone';
  } else if (/iPad/.test(user_agent)) {
    return 'iPad';
  } else if (/iPod/.test(user_agent)) {
    return 'iPod Touch';
  } else if (/iPhone/.test(user_agent)) {
    return 'iPhone';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
    return 'BlackBerry';
  } else if (/Android/.test(user_agent)) {
    return 'Android';
  } else {
    return '';
  }
};

const referringDomain = () => {
  const referrer = document.referrer;
  var split = referrer.split('/');
  if (split.length >= 3) {
    return split[2];
  }
  return '';
};

export default {
  browser,
  browserVersion,
  os,
  device,
  referringDomain
}
