import { gql } from '@apollo/client';

export const GET_GLASS_TYPES = gql`
  query {
    getGlassTypes {
      id,
      name,
    }
  }
`;

export const UPDATE_GLASS_TYPE = gql`
  mutation updateGlassType($id: ID!, $input: GlassTypeInput) {
    updateGlassType(id: $id, input: $input) {
      id,
      name,
    }
  }
`;

export const DELETE_GLASS_TYPE = gql`
  mutation deleteGlassType($id: ID!) {
    deleteGlassType(id: $id)
  }
`;

export const ADD_GLASS_TYPE = gql`
  mutation addGlassType($input: GlassTypeInput) {
    addGlassType(input: $input) {
      id,
      name,
    }
  }
`;