
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Select, Input, TextArea } from 'common/components/Form';
import Button from 'common/components/Button'
import InputSearch from 'components/InputSearch';
import RecipeTypeSelector from 'components/RecipeTypeSelector';
import RecipeCategorySelector from 'components/RecipeCategorySelector';
import TeamSelector from 'components/TeamSelector';
import UserSelector from 'components/UserSelector';
import { 
  GET_RECIPES, GET_RECIPE, UPDATE_RECIPE,
  GET_INGREDIENTS, ADD_INGREDIENT
} from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import RecipeTechiniqueSelector from 'components/RecipeTechniqueSelector';
import RecipeGlassTypeSelector from 'components/RecipeGlassTypeSelector';
import RecipeIceTypeSelector from 'components/RecipeIceTypeSelector';
import { toastSuccess, toastError } from 'dispatcher/toast';
import './Recipe.css';

const Recipe = ({ match }) => {
  const [disabled, setDisabled] = useState(true);
  const [disabled1, setDisabled1] = useState(true);
  const [elaborationSuggestions, setElaborationSuggestions] = useState([])
  const { loading: recipesLoading, error: recipesError, data: recipesPayload } = useQuery(GET_RECIPES);
  const { loading, error, data } = useQuery(GET_RECIPE, { 
    skip: !match,
    variables: { id: match?.params?.id },
  });
  const [updateRecipe] = useMutation(UPDATE_RECIPE, {
    onCompleted: ({ updateRecipe }) => {
      toastSuccess(`Receta modificada correctamente`)
    },
    onError: () => {
      toastError(`Error modificando receta`)
    }
  });

  const measurementsList = [
    "ml",
    "Dash",
    "Splash",
    "Drop",
    "Barspoon",
    "Tablespoon",
    "Teaspoon",
    "Pitch",
    "Hand",
    "gr",
  ]

  if (!match || loading || recipesLoading || !Object.keys(data).length) return <Spinner className="w3 h3 absolute-center" />;
  if (error || recipesError) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }
  const { getRecipe: recipe } = data;
  const recipes = recipesPayload.getRecipes.items;

  const modifyRecipe = (input) => {
    updateRecipe({
      variables: {
        id: recipe.id,
        input: input
      },
      refetchQueries: [
        { query: GET_RECIPE, variables: { id: recipe.id } }
      ]
    });
  }

  const handleIngredientSubmit = (e) => {
    e.preventDefault();
    const currentComponents = JSON.parse(JSON.stringify(recipe.components));
    const newComponent = {
      ingredient: e.target.elements.ingredientID.attributes["data-selected"].nodeValue,
      amount: parseFloat(e.target.elements.amount.value),
      measurement: { name: e.target.elements.measurement.value }
    }
    currentComponents.map((component) => component.ingredient = component.ingredient.id)
    currentComponents.push(newComponent);

    modifyRecipe({
      components: currentComponents
    })
    e.target.elements.ingredientID.value = '';
    e.target.elements.amount.value = '';
    e.target.elements.measurement.value = '';
  }

  const handleDecorationIngredientSubmit = (e) => {
    e.preventDefault();
    const currentComponents = recipe.decoration ? JSON.parse(JSON.stringify(recipe.decoration?.ingredients)) : [];
    const newComponent = {
      ingredient: e.target.elements.decorationIngredientID.attributes["data-selected"].nodeValue,
    }
    currentComponents.map((component) => component.ingredient = component.ingredient.id)
    currentComponents.push(newComponent);
    modifyRecipe({
      decoration: {
        description: document.getElementById('decorationDescription').value,
        ingredients: currentComponents
      }
    })
    e.target.elements.decorationIngredientID.value = '';
  }

  const handleDeleteIngredient = (e, id) => {
    const currentComponents = JSON.parse(JSON.stringify(recipe.components));
    currentComponents.map((component) => component.ingredient = component.ingredient.id)
    const payloadComponents = currentComponents.filter((component) => {
      return component.ingredient !== id;
    })
    modifyRecipe({
      components: payloadComponents
    })
  }

  const handleDeleteDecorationIngredient = (e, id) => {
    const currentComponents = JSON.parse(JSON.stringify(recipe.decoration.ingredients));
    currentComponents.map((component) => component.ingredient = component.ingredient.id)
    const payloadComponents = currentComponents.filter((component) => {
      return component.ingredient !== id;
    })
    modifyRecipe({
      decoration: {
        description: document.getElementById('decorationDescription').value,
        ingredients: payloadComponents
      }
    })
  }

  const handleAddElaborationStep = (e) => {
    e.preventDefault();
    const currentElaborationSteps = JSON.parse(JSON.stringify(recipe.elaboration));
    const newElaborationStep = {
      description: e.target.elements.stepDescription.value
    }

    currentElaborationSteps.push(newElaborationStep);

    modifyRecipe({
      elaboration: currentElaborationSteps
    })
    e.target.elements.stepDescription.value = '';
    document.getElementById('fakeInput').innerHTML = '';
  }

  const handleDeleteElaborationStep = (stepNumber) => {
    const currentElaborationSteps = recipe.elaboration ? JSON.parse(JSON.stringify(recipe.elaboration)) : [];
    const payloadElaborationSteps = currentElaborationSteps.filter((step, index) => {
      return index !== stepNumber;
    })
    modifyRecipe({
      elaboration: payloadElaborationSteps
    })
  }

  let elaborations = [];
  recipes.map(({ elaboration }) => {
    return elaboration?.map(({ description }) => {
      if(description) elaborations.push(description)
      return null;
    })
  })
  
  return (
    <div className="recipe pa8">
      <div className="image bg-image-centered"></div>
      <div className="title">
        <Input 
          id="title"
          type="text"
          defaultValue={recipe.title}
          placeholder="Título de la receta"
          label="Título de la receta"
          onBlur={(e) => modifyRecipe(({
            title: e.target.value
          }))}
        />
      </div>
      <div className="parent">
        <Select 
          id="parent"
          type="text"
          defaultValue={recipe.parent?.id}
          placeholder="Receta padre"
          label="Receta padre"
          onChange={(e) => modifyRecipe({
            parent: e.target.value
          })}
        >
          {recipes && recipes.map((r, index) => {
            if(r.id !== recipe.id) {
              return <option key={index} value={r.id}>{r.title}</option>
            }
            return null
          })}
        </Select>
      </div>
      {/* <div className="description">
        <TextArea 
          id="description"
          type="text"
          defaultValue={recipe.description}
          placeholder="Descripción de la receta"
          label="Descripción de la receta"
          onBlur={(e) => modifyRecipe(({
            description: e.target.value
          }))}
        />
      </div> */}
      <div className="components">
        <h4>Ingredientes</h4>
        <ul>
          {recipe.components.map((component, index) => { 
            return <li 
              key={component?.ingredient?.id + index}
              
            >
              <div className="flex items-center justify-center mb3">
                <span>{`${component?.amount} ${component?.measurement?.name} ${component.ingredient.name}`}</span>
                <Button primary className="ml-auto" onClick={(e) => handleDeleteIngredient(e, component.ingredient.id)}>X</Button>
              </div>
            </li>
          })}
        </ul>
        <form className="flex justify-start items-center gap" onSubmit={handleIngredientSubmit}>
          <Input
            id="amount"
            type="number"
            placeholder="Cantidad"
            step="0.01"
            required
          />
          <Select 
            id="measurement"
            placeholder="Medida"
            required={true}
          >
            {measurementsList && measurementsList.map((measurement, index) =>
              <option key={index} value={measurement}>{measurement}</option>
            )}
          </Select>
          <InputSearch
            id="ingredientID"
            placeholder="Ingrediente"
            apiQuery={GET_INGREDIENTS}
            apiMutation={ADD_INGREDIENT}
            setDisabled={setDisabled}
            required
            selector="name"
          />
          <Button primary disabled={disabled} type="submit">+</Button>
        </form>
      </div>
      <div className="decoration">
        <h4>Decoracion (TODO)</h4>
        <ul>
          {recipe.decoration?.ingredients?.map((component) => { 
            return <li 
              id={component?.ingredient?.id}
              key={component?.ingredient?.id}
              
            >
              <div className="flex items-center justify-center mb3">
                <span>{`${component?.amount || ''} ${component.measurement?.name || ''} ${component.ingredient.name}`}</span>
                <Button primary className="ml-auto" onClick={(e) => handleDeleteDecorationIngredient(e, component.ingredient.id)}>X</Button>
              </div>
            </li>
          })}
        </ul>
        <form key="atope" className="flex justify-start items-center gap" onSubmit={handleDecorationIngredientSubmit}>
          <Input
            id="decorationAmount"
            type="number"
            placeholder="Cantidad"
          />
          <Select 
            id="decorationMeasurement"
            placeholder="Medida"
          >
            {measurementsList && measurementsList.map((measurement, index) =>
              <option key={index} value={measurement}>{measurement}</option>
            )}
          </Select>
          <InputSearch
            id="decorationIngredientID"
            placeholder="Ingrediente"
            apiQuery={GET_INGREDIENTS}
            apiMutation={ADD_INGREDIENT}
            setDisabled={setDisabled1}
            required
            selector="name"
          />
          <Button primary disabled={disabled1} type="submit">+</Button>
        </form>

        <TextArea 
          id="decorationDescription"
          type="text"
          defaultValue={recipe.decoration?.description}
          placeholder="Descripción de la decoración"
          label="Descripción de la decoración"
          onBlur={(e) => {
            const aux1 = JSON.parse(JSON.stringify(recipe.decoration.ingredients));
            aux1.map((component) => component.ingredient = component.ingredient.id)
            return modifyRecipe(({
              decoration: {
                description: e.target.value,
                ingredients: aux1
              }
            }))
          }}
        />
      </div>
      <div className="category">
        <RecipeCategorySelector
          defaultValue={recipe.category?.id}
          onChange={(e) => modifyRecipe({
            category: e.target.value
          })}
        />
      </div>
      <div className="type">
        <RecipeTypeSelector 
          defaultValue={recipe.type?.id}
          onChange={(e) => modifyRecipe({
            type: e.target.value
          })}
        />
      </div>
      <div className="techniques">
        <RecipeTechiniqueSelector
          defaultValue={recipe.techniques[0]?.id}
          onChange={(e) => modifyRecipe({
            techniques: [e.target.value]
          })}
        />
      </div>
      <div className="glassType">
        <RecipeGlassTypeSelector
          defaultValue={recipe.glassType?.id}
          onChange={(e) => modifyRecipe({
            glassType: e.target.value
          })}
        />
      </div>
      <div className="iceType">
        <RecipeIceTypeSelector
          defaultValue={recipe.iceType}
          onChange={(e) => modifyRecipe({
            iceType: e.target.value
          })}
        />
      </div>
      <div className="owner">
        <UserSelector
          defaultValue={recipe.owner?.id}
          onChange={(e) => modifyRecipe({
            owner: e.target.value
          })}
        />
      </div>
      <div className="team">
        <TeamSelector
          defaultValue={recipe.team?.id}
          onChange={(e) => modifyRecipe({
            team: e.target.value
          })}
        />
      </div>
      <div className="elaboration">
        <h4>Elaboración</h4>
        {recipe.elaboration.map((step, index) => {
          return <div key={step.description+index} className="flex flex-column justify-center items-start mb6">
            <h5 className="flex items-center justify-between w-100">
              {`Paso ${index+1}`}
              <Button primary className="ml-auto" onClick={(e) => handleDeleteElaborationStep(index)}>X</Button>
            </h5>
            <TextArea 
              id={`elaborationStep${index+1}`}
              type="text"
              defaultValue={step.description}
              onBlur={(e) => {
                let currentElaboration = JSON.parse(JSON.stringify(recipe.elaboration));
                currentElaboration[index].description = e.target.value;
                const updatedElaboration = currentElaboration;
                return modifyRecipe({
                  elaboration: updatedElaboration
                })
              }}
              required
            />
          </div>
        })}
        <div className="flex flex-column justify-center items-start mb6">
          <form className="w-100" onSubmit={handleAddElaborationStep}>
            <div className="relative">
              <TextArea 
                id="stepDescription"
                type="text"
                onChange={(e) => {
                  if(!e.target.value) {
                    document.getElementById('fakeInput').innerHTML = '';
                    return setElaborationSuggestions([])
                  }
                  const matches = elaborations.filter((str) => str.match(new RegExp(e.target.value, 'i')))
                  var querystr = e.target.value;
                  var result = matches[0];
                  var reg = new RegExp(querystr, 'i');
                  const aux = result ? result.replace(reg, function(str) {return `<span class="transparent">${str}</span>`}) : querystr;
                  document.getElementById('fakeInput').innerHTML = aux;
                  setElaborationSuggestions(matches);
                }}
                placeholder="Description of step"
                required
              />
              <div id="fakeInput" className="absolute absolute--fill pe-none lowlight" style={{lineHeight: 1.15, padding: '.75em'}}>{}</div>
              {!!elaborationSuggestions.length && 
                <div className="absolute w-100 pa3 bg-white ba b--concrete" style={{ top: '90%'}}>
                  {elaborationSuggestions.map(suggestion => {
                    return <div className="pa2 pointer" onClick={() => {
                      document.getElementById('stepDescription').value = suggestion;
                      document.getElementById('fakeInput').innerHTML = '';
                      return setElaborationSuggestions([])
                    }}>{suggestion}</div>
                  })}
                </div>
              }
            </div>
            <Button primary type="submit">+</Button>
          </form>
        </div>
      </div>
      <div className="history">
        <h4>História</h4>
        <TextArea 
          id="history"
          type="text"
          defaultValue={recipe.history}
          placeholder="Historia de la receta"
          label="Historia de la receta"
          onBlur={(e) => modifyRecipe(({
            history: e.target.value
          }))}
        />
      </div>
      <div className="tastingNotes">
        <TextArea 
          id="tastingNotes"
          type="text"
          defaultValue={recipe.tastingNotes}
          placeholder="Notas de cata de la receta"
          label="Notas de cata de la receta"
          onBlur={(e) => modifyRecipe(({
            tastingNotes: e.target.value
          }))}
        />
      </div>
    </div>
  );
}

export default Recipe;
