import React, { useRef, useState } from 'react';
import Input from 'common/components/Form/Input';
import Icon from 'common/components/Icon';
import './SearchInput.css';

let activeTimeout = null;

const handleSearch = ({ textInput, searchAction, actionDelayOnChange, actionMinValueLength }) => {
  const { value } = textInput.current;
  if (value.length > 0 && value.length < actionMinValueLength) {
    return;
  }
  if (activeTimeout) {
    clearTimeout(activeTimeout);
  }
  activeTimeout = setTimeout(() => {
    searchAction({ value: value || null });
  }, actionDelayOnChange);
}

const clearSearch = ({ textInput, setInputDirty, searchAction }) => {
  textInput.current.value = '';
  searchAction({ value: null });
  setInputDirty(false);
}

export default function SearchInput({
    id, 
    className, 
    style,
    defaultValue,
    placeholder,
    searchAction = () => null,
    actionDelayOnChange = 0, // in milliseconds
    actionMinValueLength = 3,
    autoFocus,
  }) {

  const textInput = useRef(null);
  const [inputIsDirty, setInputDirty] = useState(defaultValue || false);

  return (
    <div className={`flex ${className}`}>
      <Input
        id={id}
        ref={textInput}
        type="text"
        className={className || ''}
        style={style || {}}
        defaultValue={defaultValue || ''}
        placeholder={placeholder || ''}
        autoFocus={autoFocus}
        onChange={(e) => {
          setInputDirty(e.target.value.length > 0);
          handleSearch({ textInput, searchAction, actionDelayOnChange, actionMinValueLength });
        }}
        onKeyDown={(e) => { 
          switch (e.key) {
            case 'Enter':
              searchAction({ value: textInput.current.value });
              break;
            case 'Escape':
              clearSearch({ textInput, setInputDirty, searchAction });
              break;
            default:
              return null;
          }
        }}
      />
      { inputIsDirty &&
        <Icon 
          name="close" 
          className='ClearIcon absolute-vc pointer' 
          onClick={() => { 
            clearSearch({ textInput, setInputDirty, searchAction });
          }}
        /> 
      }
      { !inputIsDirty && 
        <Icon 
          name="search" 
          className='SearchIcon absolute-vc pointer' 
          onClick={() => { handleSearch({ textInput, searchAction, actionDelayOnChange: 0, actionMinValueLength: 0 }) }}
        />
      }
    </div>
  )
}