import React from 'react';
import Button from 'common/components/Button';
import { Input } from 'common/components/Form';
import { closeModal } from 'dispatcher/modal';
import { 
  GET_USERS,
} from 'apollo/queries';

export const CreateModal = ({ createUser }) => {

  const handleCreateSubmit = (e) => {
    e.preventDefault();
    createUser({
      variables: {
        email: e.target.elements.email.value,
        password: e.target.elements.password.value,
        username: e.target.elements.username.value,
      },
      refetchQueries: [
        { query: GET_USERS }
      ]
    });
    closeModal();
  };
  return <form className="flex flex-column items-center justify-center pa8" onSubmit={handleCreateSubmit}>
    <Input 
      id="email"
      type="email"
      label={t('common:email')}
      required
      placeholder="email"
    />
    <Input 
      id="password"
      type="password"
      minLength="6"
      label={t('common:password')}
      required
      placeholder="password"
    />
    <Input 
      id="username"
      type="username"
      label={t('common:username')}
      placeholder="username"
    />
    <Button
      type="submit"
      secondary
      className="center"
    >
      + Create new user
    </Button>
  </form>
}