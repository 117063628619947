import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { LOGOUT } from 'apollo/queries';
import Topbar from 'common/structure/Topbar';
import './Fixed.css';
// import DatePicker from '@easydev/shared/components/form/DatePicker';

export default ({ height, refreshToken }) => {
  const [logout] = useLazyQuery(LOGOUT);
  const handleLogout = () => {
    const { hash, ip, userAgent } = refreshToken;
    return logout({
      variables: {
        hash,
        ip,
        userAgent
      }
    })
  }

  return (
    <React.Fragment>
      <div id="header-fixed-ghost" style={{ height }} />
      <div id="header-fixed" className="fixed w-100 z-999 top-0 bg-concrete">
      <Topbar 
        changeSidebarVisibility={() => { return null}}
        changeMobileSidebarVisibility={() => { return null}}
        logout={handleLogout}
      />
      </div>
    </React.Fragment>
  );
}
