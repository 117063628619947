export const generateLocaleDateStrings = () => {
  const i = new Date(259200000);

  for (let d = 0; d < 7; d++) {
    i18n._weekdays_long[d] = i.toLocaleDateString(i18n.language, { weekday: 'long' })
    i18n._weekdays_short[d] = i.toLocaleDateString(i18n.language, { weekday: 'short' }).slice(0, 3)
    i.setDate(i.getDate() + 1)
  }
  for (let m = 0; m < 12; m++) {
    i18n._months_long[m] = i.toLocaleDateString(i18n.language, { month: 'long' })
    i18n._months_short[m] = i.toLocaleDateString(i18n.language, { month: 'short' }).slice(0, 3)
    i.setMonth(i.getMonth() + 1)
  }
}
