import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import getRoute, { companyTeam } from 'routes/nav';
import { showModal } from 'dispatcher/modal';
import Spinner from 'common/components/Spinner';
import Button from 'common/components/Button';
import { 
  GET_COMPANY_TEAMS,
} from 'apollo/queries';
import { CreateModal } from './modals';

export default function CompanyTeams({ companyId }) {

  const { loading, error, data } = useQuery(GET_COMPANY_TEAMS, {
    variables: { companyId },
  });

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) {
    return null;
  }

  const { getCompanyTeams: teams } = data;

  return (
    <React.Fragment>
      <div className="teams">
        <div className="flex flex-row flex-grow-0 items-center pb5">
          <h3 className="mr5">{t('Equipos')}</h3>
          <div className="addTeam">
            <Button
              primary
              onClick={() => {
                showModal({
                  children: <CreateModal companyId={companyId} />
                })
              }}
            >
              {t('Nuevo Equipo +')}
            </Button>
          </div>
        </div>
        <div className="flex flex-gap">
          {teams.length === 0 && <div>No se han creado equipos</div>}
          {teams.map(team => {
            return (
              <Link key={`team_${team.id}`} className="team pa5 mr5 ba b--cherub" to={getRoute(companyTeam, { params: { companyId, teamId: team.id }})}>
                <div className="flex justify-between">
                  <label>{team.name}</label>
                </div>
                <div>
                  <span>{`${team.members.length} ${t('miembros')}`} </span>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}