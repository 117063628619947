import { withEffects, toProps } from 'redux/refractSideEffects';
import { scan, map, takeUntil, merge, losslessThrottle, pipe } from 'callbag';
import { fromEvent } from 'dispatcher'
import Toaster from './toaster';

const aperture = () => component => {
  const input$ = merge(
    pipe(
      fromEvent('TOASTER_NEW'),
      takeUntil(component.unmount),
      losslessThrottle(250),
      map(x => ({ key: Date.now(), value: x }))
    ),
    pipe(
      fromEvent('TOASTER_KILL'),
      takeUntil(component.unmount),
      losslessThrottle(250),
      map(x => ({ key: x, value: null }))
    ),
  );
  return pipe(
    input$,
    scan(({ toasting }, input) => {
      const untoasted = Object.assign({}, toasting);
      if (!input.value) delete untoasted[input.key];
      return { toasting: { ...(!!input.value && { [input.key]: input.value }), ...untoasted } }
    }, { toasting: {} }),
    map(toProps)
  )
}

const handler = () => () => null

export const ToasterEffects = withEffects(handler)(aperture)(Toaster, 'Toaster');
