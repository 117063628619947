import { withEffects } from 'redux/refractSideEffects';
import { map, pipe } from 'callbag';
import { errorPage as setErrorPage } from 'redux/actions';
import ErrorPageProvider from 'hocs/ErrorPageProvider';
import { ERROR_CODES } from 'hocs/ErrorPageProvider';

const aperture = ({ store }) => component => {
  return pipe(component.mount, map(() => ({type: 'MOUNT'})));
}

const handler = ({ store }) => effect => {
  switch(effect.type) {
    case 'MOUNT':
        store.dispatch(setErrorPage({
        code: ERROR_CODES.NOT_FOUND,
        error: {
          message: t('common:error:not_found')
        },
      }));
      break;
      default:
        return null
  }
}

export default withEffects(handler)(aperture)(ErrorPageProvider(() => null), 'NotFoundComponent');
