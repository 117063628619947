import { withEffects } from 'redux/refractSideEffects';
import { pipe, map } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import Header from './Header';
import { onRouteChange } from 'pipes/route';

const aperture = () => component =>
  pipesToProps(
    pipe(
      onRouteChange(component),
      map(route => ({ route })),
    )
  )

const handler = () => () => null;

export default withEffects(handler)(aperture)(Header, 'Header');
