import React from 'react';
import { useQuery } from '@apollo/client';
import TrashIcon from 'mdi-react/TrashIcon';
import { showModal } from 'dispatcher/modal';
import Button from 'common/components/Button';
import Spinner from 'common/components/Spinner';
import { 
  GET_TEAM,
} from 'apollo/queries';
import { DeleteModal } from './modals';
import CompanyTeamTabs from './tabs';
import './companyTeam.scss';

export default function CompanyTeam({ route, dispatchErrorPage }) {

  const { loading, error, data } = useQuery(GET_TEAM, {
    skip: !route?.match,
    variables: { id: route?.match?.params?.teamId },
  });
  if (!route?.match) return null;
  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) {
    dispatchErrorPage({
      code: error.code
    })
    return null;
  }
  
  const team = data.getTeam;
  const { name } = team;
  return (
    <div className="CompanyTeam bg-white">
      <div className="header flex justify-between pa5 bb b--pale-slate">
        <h3>Perfil del equipo: {name}</h3>
        <Button
          danger
          onClick={(e) => {
            e.preventDefault()
            return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <DeleteModal team={team} />,
              className: "bg-white"
            })
          }}
        >
          Eliminar
          <TrashIcon className="bg-red"/>
        </Button>
      </div>
      <CompanyTeamTabs route={route} team={team} />
    </div>
  )
}