import React, { Component } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import AccountAvatar from 'common/components/AccountAvatar';
import TopbarMenuLink from './TopbarMenuLink';

class TopbarProfile extends Component {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { profile, session, isFetching, logout } = this.props;
    const { collapse } = this.state;
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar flex items-center" type="button" onClick={this.toggle}>
          <AccountAvatar
            className="w2 h2 mh2 mb0"
            picture={profile ? profile.picture: ''}
            firstName={profile ? profile.username: ''}
            lastName=''
          />
          <p className="topbar__avatar-name">
            { isFetching ? 'Loading...' : (profile && profile.username)}
          </p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink
              title={t('Mi perfil')}
              icon="user"
              path="/account/profile"
              onClick={this.toggle}
            />
            {/* <TopbarMenuLink
              title="Calendar"
              icon="calendar-full"
              path="/default_pages/calendar"
              onClick={this.toggle}
            />
            <TopbarMenuLink
              title="Tasks"
              icon="list"
              path="/todo"
              onClick={this.toggle}
            />
            <TopbarMenuLink
              title="Inbox"
              icon="inbox"
              path="/mail"
              onClick={this.toggle}
            /> */}
            <div className="topbar__menu-divider" />
            {/* <TopbarMenuLink
              title="Account Settings"
              icon="cog"
              path="/account/profile"
              onClick={this.toggle}
            />
            <TopbarMenuLink
              title="Lock Screen"
              icon="lock"
              path="/lock_screen"
              onClick={this.toggle}
            /> */}
            {session && (
              <div
                title={t('Cerrar sesion')}
                className="topbar__link"
                onClick={logout}
              >
                <span 
                  className="db topbar__link-icon lnr lnr-exit"
                />
                <div className="topbar__link-title">
                  {t('Cerrar sesión')}
                </div>
              </div>
            )
            }
          </div>
        </Collapse>
      </div>
    );
  }
}

export default TopbarProfile;
