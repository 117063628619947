import React, { Component } from 'react';
// import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static defaultProps = {
    sidebarCollapse: false,
  }

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { content } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {content}
        </ul>
        <ul className="sidebar__block">
          
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
