import { withEffects } from 'redux/refractSideEffects';
import { map, pipe, merge, filter, fromEvent, wait } from 'callbag';
import { fromEvent as fromDispatcherEvent } from 'dispatcher';
import { pushEvent } from 'dispatcher';
import { pipesToProps } from 'pipes/toProps';
import { SESSION } from 'redux/actions';
import Fixed from './Fixed';

const shoutHeight = pushEvent('HEADER_FIXED_HEIGHT');

let observer;
const observerConfig = { attributes: true, childList: true, subtree: true };

const aperture = ({ store }) => component => {
  const [mutation$, mutation] = component.useEvent('MUTATION');
  observer = new MutationObserver(records => mutation(records))

  const height$ = pipe(
    merge(
      pipe(
        mutation$,
        map(records => records.reduce((_, record) =>
          record.target && record.target.id === 'header-fixed' && record
        , null)),
        filter(record => !!record),
        map(record => record.target),
      ),
      pipe(
        merge(pipe(component.mount, wait(500)), fromEvent(window, 'resize'), fromDispatcherEvent('ROUTE_CHANGED')),
        map(() => document.getElementById('header-fixed')),
      )
    ),
    map(header => ({ height: header.clientHeight, mainHeight: header.children[0]?.clientHeight })),
  );

  return merge(
    pipe(component.mount, map(() => ({ type: 'INIT'}))),
    pipesToProps(
      height$,
      pipe(
        merge(component.mount, store.observe(SESSION)),
        map(() => store.getState().user?.session),
        map(session => session || {}),
        map(({ refreshToken }) => ({ refreshToken }))
      )
    ),
    pipe(height$, map(({ height, mainHeight }) => ({ type: 'HEIGHT', height, mainHeight }))),
  )
}

const handler = () => effect => {
  switch(effect.type) {
    case 'INIT':
      observer.observe(document.getElementById('header-fixed'), observerConfig);
    break;
    case 'HEIGHT':
      shoutHeight(effect);
    break;
    default: return null
  }
}

export default withEffects(handler)(aperture)(Fixed, 'FixedHeader');
