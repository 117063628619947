import React from 'react';
import i18nInit from 'i18n';
import sentryInit from 'sentry';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { RefractProvider } from 'redux/refractSideEffects';
import { routeChanged } from 'dispatcher/route';
import getRoute from 'routes/nav';
import * as actors from 'actors';
import * as routes from 'routes/nav';
import Header from 'structure/Header';
import Nav from 'structure/Nav';
import SideBar from 'structure/SideBar';
import Aside from 'structure/Aside';
import Footer from 'structure/Footer';
import { setCurrentRoute } from 'redux/actions';
import ErrorPageProvider from 'hocs/ErrorPageProvider';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.key = null;
    sentryInit();
    i18nInit(this);
    this.ApolloClient = props.ApolloClient;
    this.hasNotFound = Object.keys(routes).find(key => key === '_not_found_');
    this.everyActor = Object.keys(actors)
      .filter(key => key !== 'default')
      .map(key => <Route key={key} component={actors[key]} />)
    this.everyNavRoute = Object.keys(routes)
      .filter(key => key !== 'default')
      .map(key => <Route key={key} exact path={getRoute(routes[key], { lng: 'ALL' })}
        render={contextProps => {
          const { match, location, history } = contextProps;
          const route = Object.assign({}, { match, location, history }, routes[key]);
          const Component = route.component;
          /*
            The following timeout is required in order to defer execution
            until the dispatcher is properly loaded.
          */
          setTimeout(() => {
            routeChanged(route);
            store.dispatch(setCurrentRoute(route));
            this.props.updateLayout(route.customLayout);
          }, 0);
          if (!routes[key].preventScrollTop) window.scrollTo(0, 0)
          const ErrorHandledComponent = ErrorPageProvider(() => <Component {...(route.forceUpdate && { key: location.key })} {...contextProps} />)
          return <ErrorHandledComponent />
        }} />);
  }

  render() {
   
    return (
      <React.Fragment>
        <Provider store={store}>
          <RefractProvider value={{ store }}>
            <BrowserRouter>
              <div className={`AppContainer relative flex flex-column ltr-support theme-light ${this.props.layout}`}>
                <Header />
                <Nav />
                <SideBar />
                {this.key &&
                  <main id="main" className="MainContainer" key={this.key}>
                    <Switch>
                      {this.everyNavRoute}
                      {this.hasNotFound &&
                        <Route component={routes[this.hasNotFound].component} />
                      }
                    </Switch>
                  </main>
                }
                <Aside />
                <Footer />
                {this.everyActor}
              </div>
            </BrowserRouter>
          </RefractProvider>
        </Provider>
      </React.Fragment>
    );
  }
}

export default App;