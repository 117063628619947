
import React, { useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Input, InputSearch } from 'common/components/Form';
import Button from 'common/components/Button';
import CustomDataTable from 'common/components/CustomDataTable';
import { showModal, closeModal } from 'dispatcher/modal';
import { 
  GET_INGREDIENTS, 
  UPDATE_INGREDIENT, 
  DELETE_INGREDIENT,
  ADD_INGREDIENT } from 'apollo/queries';
  import Spinner from 'common/components/Spinner';
import './Ingredients.css';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="flex items-center">
    {/* <Input id="search" wrapperClass="mb0" type="text" placeholder="Filter By Name" aria-label="Search Input" value={filterText} onChange={onFilter} /> */}
    <InputSearch
      id="search-input"
      className="SearchInput br2 ml5"
      defaultValue={ filterText }
      searchAction={(e) => e.value ? onFilter(e.value) : onClear()}
      placeholder={t('Search...')} 
      actionDelayOnChange={400}
      actionMinValueLength={1}
      autoFocus={true}
    />
    {/* <Button primary outline className="mb0" type="button" onClick={onClear}>X</Button> */}
  </div>
);

export default function Ingredients() {
  const { loading, error, data } = useQuery(GET_INGREDIENTS);
  const [updateIngredient] = useMutation(UPDATE_INGREDIENT, {
    onError: (e) => { console.log('INGREDIENT UPDATE ERROR', e) }
  });
  const [deleteIngredient] = useMutation(DELETE_INGREDIENT, {
    onError: (e) => { console.log('INGREDIENT DELETE ERROR', e) }
  });
  const [addIngredient] = useMutation(ADD_INGREDIENT);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleAddSubmit = (e) => {
    e.preventDefault();
    addIngredient({
      variables: {
        name: e.target.elements.name.value,
        type: e.target.elements.type.value,
        category: e.target.elements.category.value
      },
      refetchQueries: [
          { query: GET_INGREDIENTS }
      ]
    });
    closeModal();
  };

  const AddModal = () => {
    return <form className="flex flex-column items-center justify-center pa8" onSubmit={handleAddSubmit}>
      <Input 
        id="name"
        type="name"
        label={t('common:name')}
        required
        placeholder="name"
      />
      <Input 
        id="type"
        type="type"
        label={t('common:type')}
        placeholder="type"
      />
      <Input 
        id="category"
        type="category"
        label={t('common:category')}
        placeholder="category"
      />
      <Button
        primary
        type="submit"
        className="center"
      >
        Add ingredient
      </Button>
    </form>
  }

  const AddIngredientComponent = () => {
    return <div className="mr-auto">
      <Button
        primary
        type="button"
        onClick={(e) => {
          e.preventDefault()
          return showModal({
          classes: "db center mt7 w-80 ttu",
          children: <AddModal />,
          className: "bg-white"
        })}}
      >+ Add new ingredient</Button>
    </div>
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <React.Fragment>
      <AddIngredientComponent />
      <FilterComponent onFilter={e => setFilterText(e)} onClear={handleClear} filterText={filterText} />
    </React.Fragment>;
  }, [filterText, resetPaginationToggle]);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { getIngredients: ingredients } = data;

  const filteredItems = ingredients.filter(({ type, category, name }) => {
    return (type && type.toLowerCase().includes(filterText.toLowerCase())) || 
      (category && category.toLowerCase().includes(filterText.toLowerCase())) ||
      (name && name.toLowerCase().includes(filterText.toLowerCase()))
  });

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    updateIngredient({
      variables: {
        id: e.target.id,
        input: {
          type: e.target.elements.type.value,
          category: e.target.elements.category.value,
          name: e.target.elements.name.value
        }
      },
      refetchQueries: [
          { query: GET_INGREDIENTS }
      ]
    });
    closeModal();
  };

  const EditModal = ({ row }) => {
    const { name, type, category, id } = row;
    return <form id={id} className="flex flex-column items-center justify-center pa8" onSubmit={handleUpdateSubmit}>
      <Input 
        id="name"
        type="text"
        label={t('common:name')}
        required
        defaultValue={name}
      />
      <Input 
        id="type"
        type="text"
        label={t('common:type')}
        required
        defaultValue={type}
      />
      <Input
        id="category"
        type="text"
        label={t('common:category')}
        required
        defaultValue={category}
      />
      <Button
        type="submit"
        className="center"
      >
        Save
      </Button>
    </form>
  }

  const DeleteModal = ({ row }) => {
    const { id, name } = row;
    return <div id={id} className="flex flex-column items-center justify-center pa8">
      <h3 className="fw5">{`Estas seguro de que quieres borrar el ingrediente ${name}?`}</h3>
      <div className="flex items-center justify-evenly w-100 mt8">
        <Button
          primary
          type="button"
          className="center"
          onClick={(e) => {
            e.preventDefault()
            deleteIngredient({
              variables: { 
                id
              },
              refetchQueries: [
                  { query: GET_INGREDIENTS }
              ]
            })
            closeModal();
          }}
        >
          Delete
        </Button>
        <Button
          primary
          type="button"
          className="center"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
      </div>
    </div>
  }

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      grow: 2
    },
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
      grow: 2,      
    },
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
      grow: 2
    },
    {
      grow: 2,
      center: true,
      cell: (row) => {
        return <div className="flex items-center justify-evenly w-100">
          <Button
            primary
            className="mb0"
            type="button"
            onClick={(e) => {
              e.preventDefault()
              return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <EditModal row={row}/>,
              className: "bg-white"
            })}}
          >Edit</Button>
          <Button
            primary
            className="mb0"
            type="button"
            onClick={(e) => {
              e.preventDefault()
              return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <DeleteModal row={row}/>,
              className: "bg-white"
            })}}>Delete</Button>
        </div>
      },
      right: true
    }
  ];

  return (
    <div className="ingredients dataTable">
      <CustomDataTable
        title="Ingredients List"
        columns={columns}
        data={filteredItems}
        resetPaginationToggle={resetPaginationToggle}
        subHeaderComponentMemo={subHeaderComponentMemo}
        subHeader
        fixedHeader={true}
        fixedHeaderScrollHeight="calc(100vh - 17rem - 12px)"
      />
    </div>
  );
}
