
import { withEffects } from 'redux/refractSideEffects';
import { map, pipe } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import { onRouteChange } from 'pipes/route';
import Homepage from './Homepage';
import { getProfile } from 'pipes/profile';

const aperture = ({ store }) => component => pipesToProps(
  pipe(
    onRouteChange(component),
    map(route => ({ route })),
  ),
  getProfile(component, store),
)

const handler = ({ history }) => effect => null;

export default withEffects(handler)(aperture)(Homepage);
