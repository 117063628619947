
import React, { useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Input } from 'common/components/Form';
import { Button } from 'reactstrap';
import CustomDataTable from 'common/components/CustomDataTable';
import styled from 'styled-components';
import { showModal, closeModal } from 'dispatcher/modal';
import { 
  GET_TECHNIQUES, 
  UPDATE_TECHNIQUE, 
  DELETE_TECHNIQUE,
  ADD_TECHNIQUE } from 'apollo/queries';
  import Spinner from 'common/components/Spinner';
import './Techniques.css';

const TextField = styled.input`
  height: 43px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="flex items-center">
    <TextField id="search" type="text" placeholder="Filter By Name" aria-label="Search Input" value={filterText} onChange={onFilter} />
    <Button outline className="mb0" type="button" onClick={onClear}>X</Button>
  </div>
);

export default function Techniques() {
  const { loading, error, data } = useQuery(GET_TECHNIQUES);
  const [updateTechnique] = useMutation(UPDATE_TECHNIQUE, {
    onError: (e) => { console.log('TECHNIQUE UPDATE ERROR', e) }
  });
  const [deleteTechnique] = useMutation(DELETE_TECHNIQUE, {
    onError: (e) => { console.log('TECHNIQUE DELETE ERROR', e) }
  });
  const [addTechnique] = useMutation(ADD_TECHNIQUE);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleAddSubmit = (e) => {
    e.preventDefault();
    addTechnique({
      variables: {
        name: e.target.elements.name.value
      },
      refetchQueries: [
          { query: GET_TECHNIQUES }
      ]
    });
    closeModal();
  };

  const AddModal = () => {
    return <form className="flex flex-column items-center justify-center pa8" onSubmit={handleAddSubmit}>
      <Input 
        id="name"
        type="name"
        label={t('common:name')}
        required
        placeholder="name"
      />
      <Button
        type="submit"
        className="center"
      >
        Add Technique
      </Button>
    </form>
  }

  const AddTechniqueComponent = () => {
    return <div className="mr-auto">
      <Button 
        type="button"
        onClick={(e) => {
          e.preventDefault()
          return showModal({
          classes: "db center mt7 w-80 ttu",
          children: <AddModal />,
          className: "bg-white"
        })}}
      >+ Add new technique</Button>
    </div>
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <React.Fragment>
      <AddTechniqueComponent />
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    </React.Fragment>;
  }, [filterText, resetPaginationToggle]);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { getTechniques: techniques } = data;

  const filteredItems = techniques.filter(({ type, category, name }) => {
    return (type && type.toLowerCase().includes(filterText.toLowerCase())) || 
      (category && category.toLowerCase().includes(filterText.toLowerCase())) ||
      (name && name.toLowerCase().includes(filterText.toLowerCase()))
  });

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    updateTechnique({variables: {
      id: e.target.id,
      data: {
        name: e.target.elements.name.value
      }
    }});
    closeModal();
  };

  const EditModal = ({ row }) => {
    const { name, id } = row;
    return <form id={id} className="flex flex-column items-center justify-center pa8" onSubmit={handleUpdateSubmit}>
      <Input 
        id="name"
        type="name"
        label={t('common:name')}
        required
        defaultValue={name}
      />
      <Button
        type="submit"
        className="center"
      >
        Save
      </Button>
    </form>
  }

  const DeleteModal = ({ row }) => {
    const { id, name } = row;
    return <div id={id} className="flex flex-column items-center justify-center pa8">
      <h3 className="fw5">{`Estas seguro de que quieres borrar el techniquee ${name}?`}</h3>
      <div className="flex items-center justify-evenly w-100 mt8">
        <Button
          type="button"
          className="center"
          onClick={(e) => {
            e.preventDefault()
            deleteTechnique({ 
              variables: { id },
              refetchQueries: [
                { query: GET_TECHNIQUES }
            ]})
            closeModal();
          }}
        >
          Delete
        </Button>
        <Button
          type="button"
          className="center"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
      </div>
    </div>
  }

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      grow: 2
    },
    {
      grow: 2,
      center: true,
      cell: (row) => {
        return <React.Fragment>
          <Button
            className="mb0"
            outline
            type="button"
            onClick={(e) => {
              e.preventDefault()
              return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <EditModal row={row}/>,
              className: "bg-white"
            })}}
          >Edit</Button>
          <Button
            className="mb0"
            outline
            type="button"
            onClick={(e) => {
              e.preventDefault()
              return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <DeleteModal row={row}/>,
              className: "bg-white"
            })}}>Delete</Button>
        </React.Fragment>
      },
      right: true
    }
  ];

  return (
    <div className="techniques dataTable">
      <CustomDataTable
        title="Technique List"
        columns={columns}
        data={filteredItems}
        resetPaginationToggle={resetPaginationToggle}
        subHeaderComponentMemo={subHeaderComponentMemo}
        subHeader
      />
    </div>
  );
}
