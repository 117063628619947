import { pushEvent } from 'dispatcher';

const changed = pushEvent('ROUTE_CHANGED')

export let latestRoute = null;

export const routeChanged = route => {
  if (latestRoute) route.previousName = latestRoute.name;
  latestRoute = route;
  changed(route);
}
