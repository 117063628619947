import { withEffects } from 'redux/refractSideEffects';
import Roles from './Roles';

const aperture = ({ store }) => component => null

const handler = ({ history }) => effect => {
  switch(effect.type) {
    default:
      return;
  }
}

export default withEffects(handler)(aperture)(Roles, 'RolesContainer');
