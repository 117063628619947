import React from 'react';

const icons = {
  success: <svg className="white o-90 svg-fill" aria-hidden="true" height="16" width="12" viewBox="0 0 12 16"><path d="M12 5.5l-8 8-4-4L1.5 8 4 10.5 10.5 4 12 5.5z"></path></svg>,
  warning: <svg className="white o-90 svg-fill" aria-hidden="true" height="16" width="16" viewBox="0 0 16 16"><path d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"></path></svg>,
  error: <svg className="white o-90 svg-fill" aria-hidden="true" height="16" width="12" viewBox="0 0 12 16"><path d="M5.05.01c.81 2.17.41 3.38-.52 4.31C3.55 5.37 1.98 6.15.9 7.68c-1.45 2.05-1.7 6.53 3.53 7.7-2.2-1.16-2.67-4.52-.3-6.61-.61 2.03.53 3.33 1.94 2.86 1.39-.47 2.3.53 2.27 1.67-.02.78-.31 1.44-1.13 1.81 3.42-.59 4.78-3.42 4.78-5.56 0-2.84-2.53-3.22-1.25-5.61-1.52.13-2.03 1.13-1.89 2.75.09 1.08-1.02 1.8-1.86 1.33-.67-.41-.66-1.19-.06-1.78C8.18 5.01 8.68 2.15 5.05.02L5.03 0l.02.01z"></path></svg>
}

const Toast = (props) => {
  if (!props.children) return null;

  const icon = icons[props.type];
  const type = icon? props.type : null;

  return (
    <div className={`flex justify-between mb4 br2 shadow overflow-hidden bg-white ${type || 'white' } ${props.closing? 'slide-out-right' : 'slide-in-right'}`}>
      <div className={`absolute absolute--fill br2 ${type? 'o-05' : ''} bg-${type || 'darkest-blue'}`}></div>
      {icon &&
        <div className={`relative self-stretch ph3 pv5 bg-${type || 'darkest-blue'}`}>
          {icon}
        </div>
      }
      <div className="pa5 flex-auto z-999">
        {props.children}
      </div>
      <button className="close-button relative ma5" onClick={props.close}></button>
    </div>
  );
}
export default Toast;


