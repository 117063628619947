import React from 'react';
import Button from 'common/components/Button';
import CustomDataTable from 'common/components/CustomDataTable';
import Spinner from 'common/components/Spinner';
import { Link } from 'react-router-dom';
import getRoute, { recipe } from 'routes/nav';
import { showModal, closeModal } from 'dispatcher/modal';
import { useMutation } from '@apollo/client';
import { 
  GET_RECIPES, 
  DELETE_RECIPE
} from 'apollo/queries';
import TrashIcon from 'mdi-react/TrashIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import IconButton from 'common/components/CustomDataTable/IconButton';

export default function RecipesTable({
  items,
  pagination,
  subHeaderComponentMemo,
  resetPaginationToggle,
  loading,
  handleRowClicked,
}) {
  const [deleteRecipe] = useMutation(DELETE_RECIPE, {
    onError: (e) => { console.log('RECIPE DELETE ERROR', e) }
  });

  const DeleteModal = ({ row }) => {
    const { id, name } = row;
    return <div id={id} className="flex flex-column items-center justify-center pa8">
      <h3 className="fw5">{`Estas seguro de que quieres borrar el recipee ${name}?`}</h3>
      <div className="flex items-center justify-evenly w-100 mt8">
        <Button
          type="button"
          className="center"
          onClick={(e) => {
            e.preventDefault()
            deleteRecipe({ 
              variables: { 
                id 
              },
              refetchQueries: [
                  { query: GET_RECIPES }
              ]
            })
            closeModal();
          }}
        >
          Delete
        </Button>
        <Button
          primary
          type="button"
          className="center"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
      </div>
    </div>
  }

  const columns = [
    {
      name: 'Name',
      selector: 'title',
      sortable: true,
      grow: 1
    },
    {
      name: 'Category',
      selector: 'category.name',
      sortable: true,
      grow: 1,      
    },
    {
      name: 'Type',
      selector: 'type.name',
      sortable: true,
      grow: 1,      
    },
    {
      name: 'Ingredients',
      sortable: true,
      grow: 2,
      cell: (row) => {
        return <div className=" flex" style={{ columnGap: "6px", }}>
          {row.components.map((component) => {
          return <div className="" key={`${component.ingredient.name} ${component.amount}`}>
            {`${component.ingredient.name}`}
          </div>
        })}
      </div>
      }
    },
    {
      grow: .5,
      center: true,
      cell: (row) => {
        return <div className="flex items-center justify-evenly w-100">
          <Link to={getRoute(recipe, { params: { id: row.id}})}>
            <IconButton
              title="Edit"
              icon={<PencilIcon />}
            />
          </Link>
          <IconButton
            title="Delete"
            onClick={(e) => {
              e.preventDefault()
              return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <DeleteModal row={row}/>,
              className: "bg-white"
            })}}
            icon={<TrashIcon />}
          />
        </div>
      },
      right: true
    }
  ];

  return (
    <div className="recipes dataTable">
      <CustomDataTable
        title="Recipes List"
        columns={columns}
        data={items}
        resetPaginationToggle={resetPaginationToggle}
        subHeader
        subHeaderComponentMemo={subHeaderComponentMemo}
        onRowClicked={handleRowClicked}
        pagination
        paginationServer={true}
        paginationTotalRows={pagination.totalItems}
        sortServer={true}
        progressComponent={<div><Spinner className="w3 h3 absolute-center" /></div>}
        progressPending={loading}
        fixedHeader={true}
        fixedHeaderScrollHeight="calc(100vh - 17rem - 12px)"
      />
    </div>
  );
}