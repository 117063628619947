import React from 'react';
import Select from 'react-select';
import './MultiSelect.css';

const MultiSelectField = ({ value, id, placeholder, options, onChange, className }) => {
  const [selected, setSelected] = React.useState(value);

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions)
    onChange(selectedOptions);
  };

  return (
    <Select
      isMulti
      id={id}
      value={selected}
      onChange={handleChange}
      options={options}
      // clearable={true}
      // closeOnSelect={true}
      // removeSelected={true}
      className={`react-select items-center primary inline-flex flex-grow-1 ${className}`}
      placeholder={placeholder}
      classNamePrefix="react-select"
    />
  );
};

export default MultiSelectField;
