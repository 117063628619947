import React from 'react';
import Label from 'common/components/Form/Label';
import StatusMessage from 'common/components/Form/StatusMessage';
import './TextArea.css';


const TextArea = React.forwardRef(
  function TextArea(props, ref) {
    const hasError = props.error && props.error.status ? 'b--error' : '';
    const isSuccess = props.success && props.success.status ? 'b--success' : '';

    return (
      <div className="Form-group">
        {props.label && <Label id={props.id} label={props.label} className={props.labelClass}/>}
        <div className="relative">
          <textarea ref={ref} {...props} className={`TextArea outline-0 ${hasError} ${isSuccess} ${props.className || ''}`} />
          <StatusMessage
            isSuccess={isSuccess}
            hasError={hasError}
            error={props.error}
            success={props.success}
            id={props.id}
          />
        </div>
      </div>
    );
  }
);

export default TextArea;
