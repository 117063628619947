import { subject } from 'callbag'

const channel = {};

export const fromEvent = event => {
  !channel[event] && (channel[event] = subject())
  return channel[event];
}

export const pushEvent = event => {
  !channel[event] && (channel[event] = subject())
  return data => channel[event](1, data);
}

export const useEvent = event => {
  return [fromEvent(event), pushEvent(event)]
}
