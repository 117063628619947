import React, { useState, useMemo } from 'react';
import CustomDataTable from 'common/components/CustomDataTable';
import { InputSearch } from 'common/components/Form';
import {
  GET_TEAM,
  REMOVE_TEAM_MEMBER,
} from 'apollo/queries';
import Button from 'common/components/Button';
import { useMutation } from '@apollo/client';
import CloseIcon from 'mdi-react/CloseIcon';
import { toastSuccess, toastError } from 'dispatcher/toast';
import IconButton from 'common/components/CustomDataTable/IconButton';


const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="flex items-center">
    <InputSearch
      id="search-input"
      className="SearchInput br2 ml5"
      defaultValue={ filterText }
      searchAction={(e) => e.value ? onFilter(e.value) : onClear()}
      placeholder={t('Search...')} 
      actionDelayOnChange={400}
      actionMinValueLength={1}
      autoFocus={true}
    />
  </div>
);

export default function Members({ teamId, members, openInviteMembersModal }) {

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [removeTeamMember] = useMutation(REMOVE_TEAM_MEMBER, {
    onCompleted: ({ removeTeamMember }) => {
      return toastSuccess(`Successfully removed member from ${removeTeamMember.name}`);
    },
    onError: (err) => {
      return toastError('Cannot remove member from team')
    }
  });

  const InviteMembersComponent = () => {
    return (
      <div className="mr-auto">
        <Button
          primary
          type="submit"
          onClick={openInviteMembersModal}
        >
          + Add User
        </Button>
      </div>
    )
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <React.Fragment>
      <InviteMembersComponent />
      <FilterComponent onFilter={e => setFilterText(e)} onClear={handleClear} filterText={filterText} />
    </React.Fragment>;
  }, [filterText, resetPaginationToggle]);

  const filteredItems = members.filter(({ username, email }) => {
    return (username && username.toLowerCase().includes(filterText.toLowerCase())) || 
      (email && email.toLowerCase().includes(filterText.toLowerCase()))
  });

  const columns = [
    {
      name: 'Username',
      selector: 'username',
      sortable: true,
      grow: 2
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      grow: 2
    },
    {
      grow: .5,
      center: true,
      cell: (row) => {
        return <div className="flex items-center justify-evenly w-100">
          <IconButton
            title="Remove member from team"
            icon={<CloseIcon />}
            onClick={(e) => {
              e.preventDefault()
              return removeTeamMember({
                variables: {
                  teamId: teamId,
                  userId: row.id
                },
                refetchQueries: [
                  { 
                    query: GET_TEAM,
                    variables: { id: teamId }
                  }
                ]
              })
            }}
          />
        </div>
      },
      right: true
    }
  ];

  return (
    <div className="Companies dataTable">
      <CustomDataTable
        columns={columns}
        data={filteredItems}
        resetPaginationToggle={resetPaginationToggle}
        subHeaderComponentMemo={subHeaderComponentMemo}
        subHeader
        fixedHeader={true}
        fixedHeaderScrollHeight="calc(100vh - 17rem - 12px)"
      />
    </div>
  );
}