export const apiBaseUrl = 'https://api.mixole.com';
export const websocketBaseUrl = 'wss://api.mixole.com/graphql';
export const sso = {
  url: 'https://accounts.mixole.com'
}
const cdnBase = 'https://statics.mixole.com';
export const cdn = {
  app: `${cdnBase}/cocktailapp-webclient/`,
  images: `${cdnBase}/:scope/:type/:uuid/:name`,
}
