import { withEffects } from 'redux/refractSideEffects';
import { pipe, map, takeUntil, scan } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import CustomSelect from './CustomSelect';

const aperture = () => component => {
  const [toggle_select$, toggleSelect] = component.useEvent('TOGGLE_SELECT');
  const [set_value$, setValue] = component.useEvent('SET_VALUE');

  return pipesToProps(
    pipe(
      component.mount,
      takeUntil(component.unmount),
      map(() => ({
        toggleSelect,
        setValue,
      }))
    ),
    pipe(
      set_value$,
      map(value => ({ value })),
    ),
    pipe(
      toggle_select$,
      scan((prev, _) => !prev, false),
      map(selectState => ({ selectState })),
    ),
  )
}

const handler = () => effect => {
  switch (effect.type) {
    default:break;
  }
}
export default withEffects(handler)(aperture)(CustomSelect, 'CustomSelect');
