import React from 'react';
import { closeModal } from 'dispatcher/modal';
import Spinner from 'components/Spinner';
import './Modal.css';

const Modal = ({ children, className, style, asyncContent = false, contentLoaded }) => {
  if (!children) return null;

  const dontBubble = e => e.stopPropagation();
  return (
    <div className="fixed flex justify-center top-0 left-0 right-0 bottom-0 bg-white-90 z-9999" onClick={closeModal}>
      <div className="relative self-center-ns br2 ma5-ns Modal-shadow">
        <button className="Modal-close-button absolute top-1 right-1 hover-bg-black-20 silver z-5" onClick={closeModal}></button>
        <div className={`Modal-content relative br2 overflow-y-scroll overflow-y-auto-ns ${className || ''}`} onClick={dontBubble} style={style}>
          {asyncContent && !contentLoaded &&
            <Spinner className="w3 h3 absolute-center" />
          }
          {children}
        </div>
      </div>
    </div>
  );
}
export default Modal;
