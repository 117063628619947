/* eslint-disable react/no-children-prop */
import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { useMutation } from '@apollo/client';
import {
  UPDATE_USER,
  PROFILE,
} from 'apollo/queries';
import { Input, InputPassword } from 'common/components/Form';
import { toastSuccess, toastError } from 'dispatcher/toast';


export default function ProfileSettings({ profile }) {

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: ({ updateUser }) => {
      toastSuccess(`Perfil actualizado correctamente`)
    },
    onError: () => {
      toastError('Ha ocurrido un error')
    }
  });

  const resetForm = (target) => {
    const { password, repeatPassword } = target;
    password.value = '';
    repeatPassword.value = '';
    return null;
  }

  const checkSamePassword = (e) => {
    const repeatPassword = e.target;
    const password = document.getElementById('password');
    if(password.value !== repeatPassword.value) {
      return repeatPassword.setCustomValidity(t('Las contraseñas no coinciden'))
    }
    return repeatPassword.setCustomValidity('')
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const { username, email, password } = e.target;
    updateUser({
      variables: {
        id: profile.id,
        data: {
          username: username.value,
          email: email.value,
          password: password.value
        }
      },
      refetchQueries: [
        { query: PROFILE }
      ]
    })
    return resetForm(e.target);
  }

  return (
    <form className="material-form" onSubmit={handleSubmit}>
      <div>
        <Input 
          id="username"
          type="username"
          defaultValue={profile.username}
          label={t('Nombre de Usuario')}
          placeholder={t('Nombre de usuario')}
          required
        />
      </div>
      <div>
        <Input 
          id="email"
          type="email"
          defaultValue={profile.email}
          label={t('Email')}
          placeholder={t('Email')}
          required
        />
      </div>
      <div>
        <InputPassword 
          id="password"
          type="password"
          label={t('Contraseña')}
          placeholder={t('password')}
        />
      </div>
      <div>
        <InputPassword 
          id="repeatPassword"
          type="password"
          label={t('Repetir Contraseña')}
          placeholder={t('Repetir contraseña')}
          onChange={checkSamePassword}
        />
      </div>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" type="submit">Actualizar Perfil</Button>
      </ButtonToolbar>
    </form>
  );
}

