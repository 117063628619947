
import { withEffects } from 'redux/refractSideEffects';
import { onRouteChange } from 'pipes/route';
import { pipe, map, merge } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import { CURRENT_ROUTE, PROFILE, errorPage as setErrorPage}from 'redux/actions';
import Recipes from './Recipes';

const aperture = ({ store }) => component => {
  const dispatchErrorPage = ({ code, message}) => {
    store.dispatch(setErrorPage({
      code,
      error: {
        message,
      }
    }))
  }

  return (
    pipesToProps(
      pipe(
        onRouteChange(component),
        map(route => ({ route })),
      ),
      pipe(
        merge(
          component.mount,
          store.observe(CURRENT_ROUTE),
        ),
        map((effect) => {
          if (!effect) return null;
          const { match } = effect.payload;
          return { match };
        })
      ),
      pipe(
        merge(component.mount, store.observe(PROFILE)),
        map(() => store.getState().user),
        map(user => user ? user : { profile: null }),
        map(({ profile }) => ({ profile }))
      ),
      pipe(
        component.mount,
        map(() => ({
          dispatchErrorPage,
        }))
      )
    )
  )
}

const handler = () => effect => null

export default withEffects(handler)(aperture)(Recipes, 'RecipesContainer');
