
import React, { useState, useMemo } from 'react';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import Button from 'common/components/Button';
import  { InputSearch } from 'common/components/Form';
import CustomDataTable from 'common/components/CustomDataTable';
import { showModal } from 'dispatcher/modal';
import { CreateModal, UpdateModal, DeleteModal } from './modals';
import { 
  GET_USERS,
  UPDATE_USER,
  DELETE_USER,
  CREATE_USER,
  CREATE_USER_SUBSCRIPTION,
  DELETE_USER_SUBSCRIPTION,
} from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import { toastSuccess } from 'dispatcher/toast';
import Toggle from 'common/components/Toggle';
import TrashIcon from 'mdi-react/TrashIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import './Users.css';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="flex items-center">
    <InputSearch
      id="search-input"
      className="SearchInput br2 ml5"
      defaultValue={ filterText }
      searchAction={(e) => e.value ? onFilter(e.value) : onClear()}
      placeholder={t('Search...')} 
      actionDelayOnChange={400}
      actionMinValueLength={1}
      autoFocus={true}
    />
  </div>
);


export default function Users() {
  const { loading, error, data, subscribeToMore } = useQuery(GET_USERS);
  const [createUser] = useMutation(CREATE_USER, {
    onError: (err) => null,
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: ({ updateUser }) => {
      toastSuccess(`Usuario ${updateUser.username} actualizado correctamente`)
    }
  });
  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: ({ updateUser }) => {
      toastSuccess(`Usuario ${updateUser.username} borrado correctamente`)
    }
  });

  useSubscription(
    CREATE_USER_SUBSCRIPTION,
    {
      onSubscriptionData: ({ subscriptionData }) => {
        return toastSuccess(`Nuevo usuario añadido: ${subscriptionData.data.createdUser.username}`)
      }
    }
  );

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const AddUserComponent = () => {
    return <div className="mr-auto">
      <Button
        primary
        type="button"
        onClick={(e) => {
          e.preventDefault()
          return showModal({
          classes: "db center mt7 w-80 ttu",
          children: <CreateModal createUser={createUser} />,
          className: "bg-white"
        })}}
      >+ Add new user</Button>
    </div>
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <React.Fragment>
      <AddUserComponent />
      <FilterComponent onFilter={e => setFilterText(e)} onClear={handleClear} filterText={filterText} />
    </React.Fragment>;
  }, [filterText, resetPaginationToggle]);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  subscribeToMore({
    document: CREATE_USER_SUBSCRIPTION,
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      const newUser = subscriptionData.data.createdUser;
      return Object.assign({}, prev, {
        users: [newUser, ...prev.users]
      });
    }
  })

  subscribeToMore({
    document: DELETE_USER_SUBSCRIPTION,
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      const deletedUserId = subscriptionData.data.deletedUser.id;
      return Object.assign({}, prev, {
        users: prev.users.filter((user) => user.id !== deletedUserId)
      });
    }
  })
  
  const { users } = data;
  const filteredItems = users.filter(({ username, email }) => {
    return (username && username.toLowerCase().includes(filterText.toLowerCase())) || 
      (email && email.toLowerCase().includes(filterText.toLowerCase()))
  });

  const columns = [
    {
      name: 'Username',
      selector: 'username',
      sortable: true,
      grow: 2,
    },
    {
      name: 'email',
      selector: 'email',
      sortable: true,
      grow: 2,
    },
    {
      name: 'Roles',
      sortable: true,
      grow: 2,
      cell: (row) => {
        return row.roles.map(role => <div key={role.name} className="pildora">{role.name}</div>)
      }
    },
    {
      name: 'active',
      selector: 'active',
      sortable: true,
      grow: 2,
      cell: (row) => <Toggle
        id={row.id}
        checked={row.active}
        onChange={(e) => updateUser({
          variables: {
            id: row.id,
            data: {
              active: e.target.checked,
            }
          },
          refetchQueries: [
            { query: GET_USERS }
          ]
        })} />
    },
    {
      name: 'Created at',
      selector: 'createdAt',
      sortable: true,
      center: true,
      cell: (row) => {
        const formatDate = row.createdAt ? new Date(row.createdAt).toLocaleDateString() : 'Invalid Date';
        return formatDate
      }
    },
    {
      grow: 1,
      center: true,
      cell: (row) => {
        return <div className="flex items-center justify-evenly w-100">
          <div
            className="mb0 primary"
            onClick={(e) => {
              e.preventDefault()
              return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <UpdateModal row={row} updateUser={updateUser}/>,
              className: "bg-white"
            })}}
          ><PencilIcon/></div>
          <div
            className="mb0 primary"
            onClick={(e) => {
              e.preventDefault()
              return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <DeleteModal row={row} deleteUser={deleteUser}/>,
              className: "bg-white"
            })}}><TrashIcon/></div>
        </div>
      },
      right: true
    }
  ];

  return (
    <div className="entryPoints dataTable">
      <CustomDataTable
        title="Users"
        columns={columns}
        data={filteredItems}
        resetPaginationToggle={resetPaginationToggle}
        subHeader
        subHeaderComponentMemo={subHeaderComponentMemo}
        fixedHeader={true}
        fixedHeaderScrollHeight="calc(100vh - 17rem - 12px)"
      />
    </div>
  );
}
