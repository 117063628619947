import NotFound from 'components/NotFound';

export const _not_found_ = {
  name: '_not_found_',
  component: NotFound,
  paths: {
    en: '/not-found',
    es: '/no-encontrado',
    pt: '/nao-encontrado',
  },
}