import React from 'react';
import Toast from './Toast';

const Toaster = ({ toasting }) => {
  if (!toasting) return null;

  const buffet = Object.keys(toasting).map((id) => {
    const toast = toasting[id];
    return <Toast key={id} id={id} {...toast} />
  });

  return (
    <div className="fixed flex flex-column flex-column-reverse-l justify-between bottom-0 bottom-initial-l top-0-l right-0 mt5 mr5 w6 mw-100--1 z-9999">
        {buffet}
    </div>
  );
}
export default Toaster;

