import React from 'react';

const Anchor = ({ newTab: trash, ...props }) => {

  const classes = {
    anchor: "primary hover-secondary no-underline link",
  }

  const newTab = trash ? {
    target: "_blank",
    rel: "noopener noreferrer",
  } : {};

  return (
    <React.Fragment>
      <a
        { ...props }
        className={`${classes.anchor} ${props.className}`}
        { ...newTab }
        onClick={e => e.stopPropagation()}
      >
        {props.children}
      </a>
    </React.Fragment>
  );
}

export default Anchor;
