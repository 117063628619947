import React from 'react';
import Spinner from 'common/components/Spinner';
import { ERROR_CODES } from 'hocs/ErrorPageProvider';
import getRoute, { login } from 'routes/nav';

const hasRoutePermission = (route, profile) => {
  if (!route.roles) return true;

  for (const role of profile.roles) {
    if (route.roles.includes(role.name)) return true;
  }

  return false;
}

export default Wrapped => ({ session, profile, route, dispatchErrorPage }) => {
  if (!dispatchErrorPage || route === undefined) return <Spinner className="w3 h3 absolute-center" />;
  const props = { session, profile };
  switch(true) {
    case !session: {
      route.history ? route.history.push(getRoute(login)) : window.location.href = getRoute(login);
      return null
    }
    case !!Wrapped && !!session && hasRoutePermission(route, profile): return <Wrapped {...props} />
    default: {
      dispatchErrorPage({
        code: ERROR_CODES.UNAUTHORIZED,
        message: t('common:error:unauthorized')
      });
      return null
    }
  }
}
