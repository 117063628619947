import React from 'react';
import Label from 'common/components/Form/Label';
import './Checkbox.css';

const Checkbox = React.forwardRef(
  function Checkbox(props, ref) {
    return (
      <div className="Form-group-checkbox">
        <input ref={ref} {...props} type="checkbox" className={`Checkbox clip ${props.className || ''}`} />
        <Label id={props.id} label={props.label} className={props.labelClass}/>
      </div>
    );
  }
);

export default Checkbox;
