
import { withEffects } from 'redux/refractSideEffects';
import { merge, pipe, map } from 'callbag';
import { CURRENT_ROUTE } from 'redux/actions';
import { pipesToProps } from 'pipes/toProps';
import Recipe from './Recipe';

const aperture = ({ store }) => component => 
  merge(
    pipesToProps(
      pipe(
        merge(
          component.mount,
          store.observe(CURRENT_ROUTE),
        ),
        map((effect) => {
          if (!effect) return null;
          const { match } = effect.payload;
          return { match };
        })
      )
    ),
    pipe(component.mount, map(() => ({type: 'MOUNT'}))),
  )

const handler = ({ store, route, match, history}) => effect => {
  switch (effect.type) {
    case 'MOUNT':
      
    break;
    default:
      return
  }
}

export default withEffects(handler)(aperture)(Recipe, 'RecipeContainer');
