import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import getRoute, { root } from 'routes/nav';
import { ReactComponent as Missing } from './errorpage-illustration.svg';

const classes = {
  link: "Button-outlined di ml5",
}

export default function NotFound() {
  return (
    <React.Fragment>
      <div className="center-ns flex flex-row mt8 mw-m-l mw-s w-auto">
        <div className="mh5">
          <p className="black f1 mb0">{t('statics:404:title')}</p>
          <p className="iron f2 mt1">{t('statics:404:description')}</p>
          <h1 className="iron f2 fw1">{t('statics:404:errorCode', { code: 404 })}</h1>
          <p className="iron f5">{t('statics:404:helpLinksTitle')}</p>
          <Link className={classes.link} to={getRoute(root)}>{t('statics:404:home')}</Link>
        </div>
        <Missing className="dn db-l w-100 mw-s self-end ml8" />
      </div>
      <Helmet>
        <meta name="render:status_code" content="404" />
      </Helmet>
    </React.Fragment>
  );
}
