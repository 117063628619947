
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import getRoute, { recipe } from 'routes/nav';
import queryString from 'query-string';
import { Input } from 'common/components/Form';
import Button from 'common/components/Button';
import { InputSearch} from 'common/components/Form';
import { defaultRowsPerPage } from 'config';
import { showModal, closeModal } from 'dispatcher/modal';
import { 
  GET_RECIPES,
  ADD_RECIPE } from 'apollo/queries';
import RecipeTypeSelector from 'components/RecipeTypeSelector';
import RecipeCategorySelector from 'components/RecipeCategorySelector';
import RecipesTable from './RecipesTable';
import './Recipes.css';

export default function Recipes({ route, profile, dispatchErrorPage }) {
  const { page, rowsPerPage, sort, direction, search } = queryString.parse(window?.location.search);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { loading, error, data } = useQuery(GET_RECIPES, {
    variables: {
      page: parseInt(page) || 1,
      size: parseInt(rowsPerPage) || defaultRowsPerPage,
      sort: {
        key: sort || 'title',
        direction: parseInt(direction) || 1
      },
      ...(search && { search }),
      // ...(selectedFilters && { filters: selectedFilters })
    }
  });
  const [addRecipe] = useMutation(ADD_RECIPE);

  if (!route || !profile) return null;

  const handleAddSubmit = (e) => {
    e.preventDefault();
    addRecipe({
      variables: {
        input: {
          title: e.target.elements.title.value,
          type: e.target.elements.type.value,
          category: e.target.elements.category.value,
        },
      },
      refetchQueries: [
          { query: GET_RECIPES }
      ]
    });
    closeModal();
  };

  const AddModal = () => {
    return <form className="flex flex-column items-center justify-center pa8" onSubmit={handleAddSubmit}>
      <Input 
        id="title"
        type="text"
        label={t('common:title')}
        required
        autoFocus
        placeholder="Title"
      />
      <RecipeTypeSelector required={true}/>
      <RecipeCategorySelector required={true}/>
      <Button
        primary
        type="submit"
        className="center"
      >
        Add recipe
      </Button>
    </form>
  }

  const AddRecipeComponent = () => {
    return <div className="mr-auto">
      <Button 
        primary
        type="button"
        onClick={(e) => {
          e.preventDefault()
          return showModal({
          classes: "db center mt7 w-80 ttu",
          children: <AddModal />,
          className: "bg-white"
        })}}
      >+ Add new recipe</Button>
    </div>
  }

  const handleClear = () => {
    setResetPaginationToggle(!resetPaginationToggle);
    const query = queryString.parse(window?.location.search);
    delete query.search;
    route.history.push(`${window.location.pathname}?${queryString.stringify(query)}`);
  }

  const handleSearch = (value) => {
    const query = queryString.parse(window?.location.search);
    query.search = encodeURIComponent(value);
    route.history.push(`${window.location.pathname}?${queryString.stringify(query)}`);
  }

  const handleRowClicked = (row) => {
    route.history.push(getRoute(recipe, { params: { id: row.id } }))
  }

  const TableHeader = () => {
    return (
      <div className="flex items-center w-100">
        <AddRecipeComponent />
        <InputSearch
          id="search-input"
          className="SearchInput br2 ml5"
          defaultValue={ search ? decodeURIComponent(search): '' }
          searchAction={(e) => e.value ? handleSearch(e.value) : handleClear() }
          placeholder={t('Buscar...')} 
          actionDelayOnChange={400}
          actionMinValueLength={1}
          autoFocus={true}
        />
      </div>
    )
  }


  if (error) {
    dispatchErrorPage({
      code: 500,
      message: error.message,
    });
    return null;
  }

  let items = data?.getRecipes?.items;
  let pagination = data?.getRecipes?.pagination || {};

  return (
    <RecipesTable
      items={items}
      pagination={pagination}
      subHeaderComponentMemo={<TableHeader />}
      resetPaginationToggle={resetPaginationToggle}
      handleRowClicked={handleRowClicked}
      loading={loading}
    />
  );
}
