import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from 'common/components/Button';
import { MultiSelectCreatable } from 'common/components/Form';
import { closeModal } from 'dispatcher/modal';
import { toastSuccess, toastError } from 'dispatcher/toast';
import { 
  INVITE_TEAM_MEMBERS,
  GET_TEAM,
} from 'apollo/queries';

export const InviteMembers = ({ teamId, members, companyUsers }) => {

  const [inviteMembers] = useMutation(INVITE_TEAM_MEMBERS, {
    onError: () => toastError(`Error invitando miembros`),
    onCompleted: () => toastSuccess(`Invitaciones enviadas con éxito`)
  });
  const [selectedMembers, setSelectedMembers] = useState([])
  const multiSelectValue = selectedMembers;

  const filteredCompanyUsers = companyUsers.filter(user => !members.some(({ id }) => id === user.id))
  const multiSelectOptions = filteredCompanyUsers.map(user => ({
    value: user.email,
    label: user.username
  }))

  const handleSubmit = (e) => {
    e.preventDefault();
    const emails = selectedMembers.map(field => field.value);
    inviteMembers({
      variables: {
        teamId,
        emails,
      },
      refetchQueries: [
        { query: GET_TEAM, variables: { id: teamId }}
      ]
    });
    closeModal();
  }

  return <form className="flex flex-column items-center justify-center pa8 bg-white" onSubmit={handleSubmit}>
    <div className="mb2">
      <h4>Invita nuevos usuarios</h4>
    </div>
    <div className="mb5">
      <MultiSelectCreatable
        label={t('common:roles')}
        value={multiSelectValue}
        id="membersSelect"
        onChange={(selectedElements) => {
          setSelectedMembers(selectedElements);
        }}
        options={multiSelectOptions}
        placeholder="Selecciona un usuario"
        isValidNewOption={(inputValue) => {
          const emailRegex = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')
          const isEmail = !!inputValue.match(emailRegex)
          return isEmail;
        }}
      />
    </div>
    <div className="mt5">
      <Button
        primary
        type="submit"
      >
        Invitar
      </Button>
    </div>
  </form>
}

