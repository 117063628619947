import React, { useState } from 'react';
import Label from 'common/components/Form/Label';
import StatusMessage from 'common/components/Form/StatusMessage';
import 'common/components/Form/Input/Input.css';
import './InputPassword.scss';

const EyeShow = ({ className }) => <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 256 256"><path d="M160 128c0 17.6-14.4 32-32 32s-32-14.4-32-32 14.4-32 32-32 32 14.4 32 32zm96-4.8s-45.4 90.1-127.8 90.1C51.6 213.3 0 123.2 0 123.2s47.4-80.5 128.2-80.5c82 0 127.8 80.5 127.8 80.5zm-74.7 4.8c0-29.4-23.9-53.3-53.3-53.3S74.7 98.6 74.7 128s23.9 53.3 53.3 53.3 53.3-23.9 53.3-53.3z"/></svg>;

const EyeHide = ({ className }) => <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 256 256"><path d="M126.8 159.9l33.1-33 .1 1.2c0 17.6-14.4 32-32 32 0-.1-1.2-.2-1.2-.2zm85.8-85.7l-34.9 34.9c2.3 5.9 3.6 12.3 3.6 18.9 0 29.4-23.9 53.3-53.3 53.3-6.7 0-13.1-1.4-19-3.7L83.6 203c13.6 6.3 28.5 10.4 44.6 10.4 82.5 0 127.8-90.1 127.8-90.1s-15.1-26.5-43.4-49.1zm15.3-37.7L29.3 234.7l-14.7-15.2 36.9-36.8C19.2 156.6 0 123.2 0 123.2s47.4-80.5 128.2-80.5c19.5 0 36.9 4.5 52.1 11.5l32.9-32.8c-.1-.1 14.7 15.1 14.7 15.1zM81.2 153l16.2-16.2c-.8-2.8-1.4-5.7-1.4-8.8 0-17.6 14.4-32 32-32 3.1 0 6 .6 8.9 1.4l16.2-16.2c-7.5-4.1-16-6.6-25.1-6.6-29.4 0-53.3 23.9-53.3 53.3 0 9.2 2.5 17.6 6.5 25.1z"/></svg>;

const InputPassword = React.forwardRef(
  function InputPassword(props, ref) {
    const [visibilityStatus, setVisibilityStatus] = useState(false);
    const hasError = props.error && props.error.status ? 'b--error' : '';
    const isSuccess = props.success && props.success.status ? 'b--success' : '';
    const eyeColor = hasError ? 'error' : isSuccess ? 'success' : 'silver';

    return !props.id ? 'Please set and ID for this input' : (
      <div className="Form-group">
        { props.label && <Label id={props.id} label={props.label} className={props.labelClass}/> }
        <div className="InputPassword relative flex">
          <input
            ref={ref}
            id={props.id}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            value={props.value}
            autoComplete={props.autoComplete || 'off'}
            required={props.required}
            onChange={props.onChange}
            maxLength={props.maxLength}
            disabled={props.disabled}
            className={`Input outline-0 br--left flex-grow-1 ${hasError} ${isSuccess} ${props.className || ''}`}
            type={visibilityStatus ? 'text' : 'password'}
          />
          <span className="InputPassword-trigger br--right br2" onClick={() => setVisibilityStatus(!visibilityStatus)}>
            { visibilityStatus ? <EyeHide className={`svg-fill ${eyeColor}`} /> : <EyeShow className={`svg-fill ${eyeColor}`} />}
          </span>
        </div>
        <StatusMessage
          isSuccess={isSuccess}
          hasError={hasError}
          error={props.error}
          success={props.success}
          id={props.id}
        />
      </div>
    );
  }
);

export default InputPassword;
