import securedWithProfile from 'hocs/securedWithProfile';
import Users from 'common/containers/Users';
import Roles from 'common/containers/Roles';
import Profile from 'common/containers/Profile';
import Homepage from 'containers/Homepage';
import Login from 'containers/Login';
import Ingredients from 'containers/Ingredients';
import Recipes from 'containers/Recipes';
import Techniques from 'containers/Techniques';
import Recipe from 'containers/Recipe';
import RecipeCategories from 'containers/RecipeCategories';
import RecipeTypes from 'containers/RecipeTypes';
import GlassTypes from 'containers/GlassTypes';
import Companies from 'containers/Companies';
import Teams from 'containers/Teams';
import Invite from 'containers/Invite';
import Terms from 'containers/Terms';
import { PROJECT_ROLES } from 'config';
export * from './companies';

export const root = {
  title: "Home",
  component: securedWithProfile(Homepage),
  paths: {
    en: '/',
    es: '/',
    pt: '/',
  }
};

export const ingredients = {
  title: "Ingredientes",
  component: securedWithProfile(Ingredients),
  paths: {
    en: '/ingredientes',
    es: '/ingredients',
    pt: '/ingredients',
  }
};

export const recipes = {
  title: "Listado",
  component: securedWithProfile(Recipes),
  paths: {
    en: '/recetas',
    es: '/recetas',
    pt: '/recetas',
  }
};

export const recipe = {
  title: "Detalle",
  component: securedWithProfile(Recipe),
  paths: {
    en: '/receta/:id',
    es: '/receta/:id',
    pt: '/receta/:id',
  }
};

export const techniques = {
  title: "Técnicas",
  component: securedWithProfile(Techniques),
  paths: {
    en: '/tecnicas',
    es: '/techniques',
    pt: '/techniques',
  }
};

export const categories = {
  title: "Categorias",
  component: securedWithProfile(RecipeCategories),
  paths: {
    en: '/categorias',
    es: '/categories',
    pt: '/categories',
  }
};

export const types = {
  title: "Tipos",
  component: securedWithProfile(RecipeTypes),
  paths: {
    en: '/tipos',
    es: '/types',
    pt: '/types',
  }
};

export const glassTypes = {
  title: "Tipos de Vaso",
  component: securedWithProfile(GlassTypes),
  paths: {
    en: '/tipos-de-vaso',
    es: '/glass-types',
    pt: '/glass-types',
  }
};

export const login = {
  title: "Login",
  component: Login,
  paths: {
    en: '/login',
    es: '/login',
    pt: '/login',
  },
  hideHeader: true,
  hideFooter: true,
  hideSidebar: true,
  hideFromNav: true,
  customLayout: 'flex items-center justify-center vh-100'
};

export const users = {
  title: "Usuarios",
  component: securedWithProfile(Users),
  paths: {
    en: '/users',
    es: '/users',
    pt: '/users',
  },
  roles: [PROJECT_ROLES.SUPER_ADMIN]
};

export const roles = {
  title: "Roles",
  component: securedWithProfile(Roles),
  paths: {
    en: '/roles',
    es: '/roles',
    pt: '/roles',
  },
  roles: [PROJECT_ROLES.SUPER_ADMIN]
};

export const profile = {
  component: securedWithProfile(Profile),
  title: "Profile",
  description: "Account profile page",
  paths: {
    en: '/account/profile',
    es: '/account/profile',
    pt: '/account/profile',
  },
  hideFromNav: true
};

export const companies = {
  title: "Companies",
  component: securedWithProfile(Companies),
  paths: {
    en: '/companies',
    es: '/companies',
    pt: '/companies',
  }
};

export const teams = {
  title: "Teams",
  component: securedWithProfile(Teams),
  paths: {
    en: '/teams',
    es: '/teams',
    pt: '/teams',
  }
};

export const invite = {
  title: "Invite",
  component: Invite,
  paths: {
    en: '/invite/:inviteHash',
    es: '/invite/:inviteHash',
    pt: '/invite/:inviteHash',
  },
  hideHeader: true,
  hideFooter: true,
  hideSidebar: true,
  hideFromNav: true,
  customLayout: 'flex items-center justify-center vh-100'
};

export const terms = {
  title: "Terminos y Condiciones",
  component: Terms,
  paths: {
    en: '/terms',
    es: '/terms',
    pt: '/terms',
  }
};
