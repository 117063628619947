import React from 'react';
import { ReactComponent as MalwareIcon } from '../svg/malware.svg';

const ReportButton = ({ name, description, logo, className }) => {

  return (
    <div 
      className={`ReportButton w-100 h-100 flex items-center justify-content-center ${ className || '' }`}
      onClick={() => {}}
    >
      <MalwareIcon className="svg-fill default h1 w2 ma0" />
      <a href="https://digitaltack.atlassian.net/servicedesk/customer/portal/1/group/1/create/7" target="_blank" rel="noopener noreferrer">
        Reportar una incidencia
      </a>
    </div>
  );
}

export default ReportButton;
