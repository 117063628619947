import React from 'react';
import '../Select/Select.css';

const Select = props => <input {...props} type="checkbox" className="clip" />
const Option = Select;

const classes = {
  wrapper: "Select-wrapper",
  ul: "absolute bg-theme-back w-100 list ma0 pa0 z-1",
  label: "db pa4 bb bl br b--theme-mid bw--selected pointer hover-bg-primary",
  outside: "fixed absolute--fill z-0",
  customSelect: "w-100 inline-flex items-center outline-0 ba bc--selected b--transition pa4 pr7 pointer",
}

const CustomSelect = ({
  children, id, className = '',
  selectState = false, toggleSelect,
  defaultValue, value, setValue,
  onChange,
}) => {
  if (!toggleSelect || !React.Children.count(children)) return null;
  const selected = value || defaultValue || children[0];
  return (
    <div id={id} className={`${classes.wrapper} ${className}`}>
      <Select
        id={`select-${id}`}
        onChange={toggleSelect}
      />
      <label
        htmlFor={`select-${id}`}
        className={classes.customSelect}
      >
        {selected}
      </label>
      {selectState &&
        <React.Fragment>
          <label htmlFor={`select-${id}`} className={classes.outside} />
          <ul className={classes.ul}>
            {React.Children.map(children, child => {
              if (selected.props.id === child.props.id) return null;
              return (
                <li>
                  <Option
                    id={`option-${child.props.id}`}
                    name={`name-${id}`}
                    onChange={() => {
                      onChange && onChange(child);
                      setValue(child);
                      document.getElementById(`select-${id}`).click()
                    }}
                  />
                  <label
                    htmlFor={`option-${child.props.id}`}
                    className={classes.label}
                  >{child}</label>
                </li>
              )
            })}
          </ul>
        </React.Fragment>
      }
    </div>
  );
}

export default CustomSelect;

/*
When using CustomSelect with inside Link elements, in order to navigate to the selected route, add this
to the Link element inside:
          onClick={() => {
            const option = document.getElementById(`option-${Link.id}`);
            if (option) {
              option.click()
            } else {
              const select = document.getElementById(`select-${CustomSelect.id}`);
              select && select.click()
            }
          }}
*/
