
import securedWithProfile from 'hocs/securedWithProfile';
import Companies from 'containers/Companies';
import Company from 'containers/Company';
import CompanyTeam from 'containers/CompanyTeam';

export const companies = {
  title: "Companies",
  component: securedWithProfile(Companies),
  paths: {
    en: '/companies',
    es: '/companies',
    pt: '/companies',
  }
};

export const company = {
  title: "Company",
  component: securedWithProfile(Company),
  paths: {
    en: '/companies/:id',
    es: '/companies/:id',
    pt: '/companies/:id',
  }
};

export const companyTeam = {
  title: "Company Team",
  component: securedWithProfile(CompanyTeam),
  paths: {
    en: '/companies/:companyId/teams/:teamId',
    es: '/companies/:companyId/teams/:teamId',
    pt: '/companies/:companyId/teams/:teamId',
  }
};