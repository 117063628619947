
import { withEffects } from 'redux/refractSideEffects';
import { pipe, map } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import { onRouteChange } from 'pipes/route';
import Companies from './Companies';

const aperture = ({ store }) => component => {

  return (
    pipesToProps(
      pipe(
        onRouteChange(component),
        map(route => ({ route })),
      ),
    )
  )
}

const handler = () => effect => null

export default withEffects(handler)(aperture)(Companies, 'CompaniesContainer');
