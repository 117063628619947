import React from 'react';
import Button from 'common/components/Button';
import { closeModal } from 'dispatcher/modal';
import { GET_USERS } from 'apollo/queries';


export const DeleteModal = ({ row, deleteUser }) => {
  
  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    deleteUser({ 
      variables: { id: e.target.id },
      refetchQueries: [
        { query: GET_USERS }
      ]
    });
    closeModal();
  }
  
  const { id, username } = row;

  return <form id={id} className="flex flex-column items-center justify-center pa8" onSubmit={handleDeleteSubmit}>
    <h3 className="fw5 tc" style={{ maxWidth: '22rem'}}>Estas seguro de que quieres borrar el usuario <span className="b">{username}</span>?</h3>
    <div className="flex items-center justify-evenly w-100 mt8">
      <Button
        type="submit"
        secondary
        className="ph8"
      >
        Delete
      </Button>
      <Button
        type="button"
        secondary
        className="ph8"
        onClick={() => closeModal()}
      >
        Cancel
      </Button>
    </div>
  </form>
}