import React from 'react';

export default function IconButton({ className, title, onClick, icon }) {
  return (
    <div
      className={`mb0 ml-auto primary hover-secondary ${className}`}
      title={title}
      onClick={onClick}
    >{icon}</div>
  )
}
