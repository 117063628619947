import { map, pipe, merge } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import { fromEvent } from 'dispatcher';
import { PROFILE } from 'redux/actions';

export const profileToProps = (component, store) =>
  pipesToProps(
    getProfile(component, store),
  )

export const getProfile = (component, store) => {
  return merge(
    pipe(
      merge(component.mount, store.observe(PROFILE)),
      map(() => store.getState().user),
      map(user => user ? user : { profile: null }),
      map(({ profile, impersonate, impersonate_type }) => ({ profile, impersonate, impersonate_type }))
    ),
    pipe(
      fromEvent('LANGUAGE_CHANGED'),
      map(x => ({ language: x }))
    )
  )
}
