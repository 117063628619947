import React from 'react';
import Sidebar from 'common/structure/Sidebar/Sidebar';
import SidebarLink from 'common/structure/Sidebar/SidebarLink';
import { AboutInfoButton, AboutReportButton } from 'common/components/About';
import SidebarCategory from 'common/structure/Sidebar/SidebarCategory';
import './SideBar.css';

export default ({ profile, height, route }) => {
  if (!profile || !height || (route && route.hideSidebar)) return null;
  const sidebarContent = <React.Fragment>
     <SidebarCategory title="Recetas" icon="diamond">
      <SidebarLink
        title="Listado"
        icon="home"
        route="/recetas"
      />
      <SidebarLink
        title="Ingredientes"
        icon="home"
        route="/ingredientes"
      />
      <SidebarLink
        title="Técnicas"
        icon="home"
        route="/tecnicas"
      />
      <SidebarLink
        title="Categorias"
        icon="home"
        route="/categorias"
      />
      <SidebarLink
        title="Tipos de receta"
        icon="home"
        route="/tipos"
      />
      <SidebarLink
        title="Tipos de Vaso"
        icon="home"
        route="/tipos-de-vaso"
      />
    </SidebarCategory>
    <SidebarCategory title="Companies" icon="diamond">
      <SidebarLink
        title="Listado"
        icon="home"
        route="/companies"
      />
      <SidebarLink
        title="Equipos"
        icon="users"
        route="/teams"
      />
    </SidebarCategory>
    <SidebarLink
      title="Users"
      icon="user"
      route="/users"
    />
    <SidebarLink
      title="Roles"
      icon="user"
      route="/roles"
    />
  </React.Fragment>
  
  return (
    <div
      id="sidebar"
      className="fixed overflow-auto"
      style={{ height: `calc(100vh - ${height}px)`, top: `${height}px`, width: '240px', userSelect: 'none' }}
    >
      <Sidebar 
        sidebar={{ 
          show: false,
          content: sidebarContent}}
        changeToDark={() => { return null}}
        changeToLight={() => { return null}}
        changeMobileSidebarVisibility={() => { return null}}
      />
      <div className="absolute bottom-0 z-999 m-0 pa0">
        <div className="bold-text" style={{ height: '3rem' }}>
          <AboutReportButton />
        </div>
        <div className="sidebar__link bold-text" style={{ height: '3rem' }}>
          <AboutInfoButton
            name={'Cocktailapp'}
            logo={'/images/logo/logo-cocktailapp.svg'}
            description={t('Con Cocktailapp podrás formar y gestionar tus equipos de cocktelería')}
          />
        </div>
      </div>
    </div>
  )
}