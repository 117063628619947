
import { withEffects } from 'redux/refractSideEffects';
import Homepage from './Homepage';

const aperture = () => component => null

const handler = () => effect => {
  switch(effect.type) {
    default: return null
  }
}

export default withEffects(handler)(aperture)(Homepage);
