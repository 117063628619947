import { ApolloClient, ApolloLink, createHttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { isDevEnvironment } from 'utils';
import { apiBaseUrl, websocketBaseUrl } from 'config';
import customLinks from 'apollo/links';
import { cache } from './cache';
// import { customFetch } from 'pipes/graphql'; // TO DO

const httpLink = createHttpLink({
  uri: apiBaseUrl,
  // fetch: customFetch
});


const wsLink = new WebSocketLink({
  uri: websocketBaseUrl,
  options: {
    reconnect: true
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
  mutate: {
  }
}

// this client should be configurable by project
export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([customLinks, splitLink]),
  connectToDevTools: !!isDevEnvironment,
  defaultOptions
});
