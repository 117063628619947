import { createContext } from 'react'
import { withEffects as _withEffects } from 'refract-callbag'
import { contextToProps } from 'react-zap'
export { toProps, asProps } from 'refract-callbag'

const RefractContext = createContext()

const enhancedWithEffects = (handler, errorHandler) => aperture => (BaseComponent, name = 'Unnamed') => {
  if (BaseComponent) BaseComponent.displayName = name;
  const we = _withEffects(handler, errorHandler, null)(aperture)(BaseComponent)
  we.displayName = `${name}WithEffects`
  const ctp = contextToProps(RefractContext.Consumer)(we);
  ctp.displayName = `${name}ContextToProps`
  return ctp;
}
export const RefractProvider = RefractContext.Provider;
export const withEffects = enhancedWithEffects;
