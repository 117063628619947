import { gql } from '@apollo/client';

export const GET_INGREDIENTS = gql`
  query getIngredients {
    getIngredients {
      id,
      name,
      type,
      category
    }
  }
`;

export const UPDATE_INGREDIENT = gql`
  mutation updateIngredient($id: ID!, $input: IngredientInput) {
    updateIngredient(id: $id, input: $input) {
      id,
      name,
      type,
      category
    }
  }
`;

export const DELETE_INGREDIENT = gql`
  mutation deleteIngredient($id: ID!) {
    deleteIngredient(id: $id)
  }
`;

export const ADD_INGREDIENT = gql`
  mutation addIngredient($name: String, $type: String, $category: String) {
    addIngredient(name: $name, type: $type, category: $category) {
      id,
      name,
      type,
      category
    }
  }
`;