

import React from 'react';
import './Toggle.css';

export default function Toggle({ id, checked, onChange, labelText }) {
  return (<label className="toggle-btn customizer__toggle" htmlFor={`toggle_${id}`}>
    <input
      className="toggle-btn__input"
      type="checkbox"
      name={`toggle_${id}`}
      id={`toggle_${id}`}
      checked={checked}
      onChange={onChange}
    />
    <span className="toggle-btn__input-label" />
    {!!labelText && <span>{labelText}</span>}
  </label>)
}
