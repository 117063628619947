import { pushEvent } from 'dispatcher'

const newToast = pushEvent('TOASTER_NEW')

export const toast = props =>
  newToast({ children: props })

export const toastSuccess = props =>
  newToast({ children: props, type: 'success' })

export const toastError = props =>
  newToast({ children: props, type: 'error' })

export const toastWarning = props =>
  newToast({ children: props, type: 'warning' })

const killToast = pushEvent('TOASTER_KILL')

export const toasterKill = id =>
  killToast(id)