import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from 'i18next-xhr-backend';
import { pushEvent } from 'dispatcher';
import DetectionOptions from './DetectionOptions';
import BackendOptions from './BackendOptions';
import { generateLocaleDateStrings } from './Dates';

const languageChanged = pushEvent('LANGUAGE_CHANGED')

export default (app) => {
  window.t = () => '☴☵☷☲';
  i18n.app = app;
  window.i18n = i18n;
  window.i18n._months_long = [...Array(12)];
  window.i18n._months_short = [...Array(12)];
  window.i18n._weekdays_long = [...Array(7)];
  window.i18n._weekdays_short = [...Array(7)];
  const ns = process.env.REACT_APP_i18nNS && process.env.REACT_APP_i18nNS.split(',');
  const wl = process.env.REACT_APP_languages && process.env.REACT_APP_languages.split(',');
  i18n
    .use(LanguageDetector)
    .use(XHR)
    .init({
      detection: DetectionOptions,
      backend: BackendOptions,
      fallbackLng: process.env.REACT_APP_i18nFallbackLng,
      whitelist: wl,
      ns: ns,
      defaultNS: process.env.REACT_APP_i18nDefaultNS || ns[0],
      debug: false && process.env.NODE_ENV !== 'production',
      keySeparator: '::'
    },
    (err, t) => {
      if (err) {
        console.warn('i18n failed to init', err);
      } else {
        generateLocaleDateStrings();
        window.t = t;
        app.key = 'lang';
        app.forceUpdate && app.forceUpdate();
        languageChanged();
      }
    })
}

// docs: https://www.i18next.com/overview/configuration-options
