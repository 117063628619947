import React from 'react';
import { ERROR_PAGES } from './index.js';

export default function ErrorPageProvider (Wrapped) {
  return ({ code, error }) => {
    if (!code || !error) return <Wrapped />;
    const ErrorPage = ERROR_PAGES.find(page => {
      return page.code === code;
    })
    if(ErrorPage) {
      return <ErrorPage.component {...error} />
    }
    return <Wrapped />
  }
}
