import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import getRoute, { root } from 'routes/nav';
import { ReactComponent as ISR } from './internal-server-error.svg';

const classes = {
  link: "link primary hover-secondary",
}

export default function InternalServerError() {
  return (
    <React.Fragment>
      <div className="center-ns flex flex-row mt8 mw-l-l mw-s w-auto">
        <div className="mh5">
          <p className="black f1 mb0">{t('statics:500:title')}</p>
          <p className="iron f2 mt1">{t('statics:500:description')}</p>
          <h1 className="iron f2 fw1">{t('statics:500:errorCode', { code: 500 })}</h1>
          <p className="iron f5">{t('statics:500:helpButtonsTitle')}</p>
          <p>
          <Link className={classes.link} to={getRoute(root)}>{t('statics:500:home')}</Link>
          </p>
        </div>
        <ISR className="dn db-l w-100 mw-m self-end ml8" />
      </div>
      <Helmet>
        <meta name="render:status_code" content="500" />
      </Helmet>
    </React.Fragment>
  );
}
