
import React, { useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Input, InputSearch } from 'common/components/Form';
import Button from 'common/components/Button';
import CustomDataTable from 'common/components/CustomDataTable';
import { showModal, closeModal } from 'dispatcher/modal';
import getRoute, { company } from 'routes/nav';
import { 
  GET_COMPANIES, 
  UPDATE_COMPANY, 
  DELETE_COMPANY,
  ADD_COMPANY } from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import IconButton from 'common/components/CustomDataTable/IconButton';
import TrashIcon from 'mdi-react/TrashIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import './Companies.css';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="flex items-center">
    {/* <Input id="search" wrapperClass="mb0" type="text" placeholder="Filter By Name" aria-label="Search Input" value={filterText} onChange={onFilter} /> */}
    <InputSearch
      id="search-input"
      className="SearchInput br2 ml5"
      defaultValue={ filterText }
      searchAction={(e) => e.value ? onFilter(e.value) : onClear()}
      placeholder={t('Search...')} 
      actionDelayOnChange={400}
      actionMinValueLength={1}
      autoFocus={true}
    />
    {/* <Button primary outline className="mb0" type="button" onClick={onClear}>X</Button> */}
  </div>
);

export default function Companies({ route }) {
  const { loading, error, data } = useQuery(GET_COMPANIES);
  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    onError: (e) => { console.log('COMPANY UPDATE ERROR', e) }
  });
  const [deleteCompany] = useMutation(DELETE_COMPANY, {
    onError: (e) => { console.log('COMPANY DELETE ERROR', e) }
  });
  const [addCompany] = useMutation(ADD_COMPANY);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleAddSubmit = (e) => {
    e.preventDefault();
    addCompany({
      variables: {
        data: {
          name: e.target.elements.name.value,
          address: e.target.elements.address.value,
          vatNumber: e.target.elements.vatNumber.value,
          phone: e.target.elements.phone.value,
        }
      },
      refetchQueries: [
          { query: GET_COMPANIES }
      ]
    });
    closeModal();
  };

  const CompanyForm = ({ row }) => <React.Fragment>
    <Input 
      id="name"
      type="text"
      label={t('common:name')}
      required
      placeholder="Company Name"
      defaultValue={row?.name}
    />
    <Input 
      id="address"
      type="text"
      label={t('common:address')}
      placeholder="Address"
      defaultValue={row?.address}
    />
    <Input 
      id="vatNumber"
      type="text"
      label={t('common:vatNumber')}
      placeholder="VAT Number"
      defaultValue={row?.vatNumber}
    />
    <Input 
      id="phone"
      type="text"
      label={t('common:phone')}
      placeholder="Phone Number"
      defaultValue={row?.phone}
    />
  </React.Fragment>

  const AddModal = () => {
    return <form className="flex flex-column items-center justify-center pa8" onSubmit={handleAddSubmit}>
      <CompanyForm />
      <Button
        primary
        type="submit"
        className="center"
      >
        Add Company
      </Button>
    </form>
  }

  const AddCompanyComponent = () => {
    return <div className="mr-auto">
      <Button
        primary
        type="button"
        onClick={(e) => {
          e.preventDefault()
          return showModal({
          classes: "db center mt7 w-80 ttu",
          children: <AddModal />,
          className: "bg-white"
        })}}
      >+ Add new Company</Button>
    </div>
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <React.Fragment>
      <AddCompanyComponent />
      <FilterComponent onFilter={e => setFilterText(e)} onClear={handleClear} filterText={filterText} />
    </React.Fragment>;
  }, [filterText, resetPaginationToggle]);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { getCompanies: Companies } = data;

  const filteredItems = Companies.filter(({ type, category, name }) => {
    return (type && type.toLowerCase().includes(filterText.toLowerCase())) || 
      (category && category.toLowerCase().includes(filterText.toLowerCase())) ||
      (name && name.toLowerCase().includes(filterText.toLowerCase()))
  });

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    updateCompany({
      variables: {
        id: e.target.id,
        data: {
          name: e.target.elements.name.value,
          address: e.target.elements.address.value,
          vatNumber: e.target.elements.vatNumber.value,
          phone: e.target.elements.phone.value,
        }
      },
      refetchQueries: [
          { query: GET_COMPANIES }
      ]
    });
    closeModal();
  };

  const EditModal = ({ row }) => {
    const { id } = row;
    return <form id={id} className="flex flex-column items-center justify-center pa8" onSubmit={handleUpdateSubmit}>
      <CompanyForm row={row} />
      <Button
        primary
        type="submit"
        className="center"
      >
        Save
      </Button>
    </form>
  }

  const DeleteModal = ({ row }) => {
    const { id, name } = row;
    return <div id={id} className="flex flex-column items-center justify-center pa8">
      <h3 className="fw5">{`Estas seguro de que quieres borrar el Companye ${name}?`}</h3>
      <div className="flex items-center justify-evenly w-100 mt8">
        <Button
          primary
          type="button"
          className="center"
          onClick={(e) => {
            e.preventDefault()
            deleteCompany({
              variables: { 
                id
              },
              refetchQueries: [
                  { query: GET_COMPANIES }
              ]
            })
            closeModal();
          }}
        >
          Delete
        </Button>
        <Button
          primary
          type="button"
          className="center"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
      </div>
    </div>
  }

  const handleRowClicked = (row) => {
    route.history.push(getRoute(company, { params: { id: row.id } }))
  }

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      grow: 2
    },
    {
      name: 'Address',
      selector: 'address',
      sortable: true,
      grow: 2,      
    },
    {
      name: 'VAT Number',
      selector: 'vatNumber',
      sortable: true,
      grow: 2
    },
    {
      name: 'Phone Number',
      selector: 'phone',
      sortable: true,
      grow: 2
    },
    {
      grow: .5,
      center: true,
      cell: (row) => {
        return <div className="flex items-center justify-evenly w-100">
          <IconButton
            title="Edit"
            icon={<PencilIcon />}
            onClick={(e) => {
              e.preventDefault()
              return showModal({
              classes: "db center mt7 w-80 ttu",
              children: <EditModal row={row}/>,
              className: "bg-white"
            })}}
          />
          <IconButton
            title="Delete"
            onClick={(e) => {
              e.preventDefault()
              return showModal({
                classes: "db center mt7 w-80 ttu",
                children: <DeleteModal row={row}/>,
                className: "bg-white"
              })
            }}
            icon={<TrashIcon />}
          />
        </div>
      },
      right: true
    }
  ];

  return (
    <div className="Companies dataTable">
      <CustomDataTable
        title="Companies List"
        columns={columns}
        data={filteredItems}
        onRowClicked={handleRowClicked}
        resetPaginationToggle={resetPaginationToggle}
        subHeaderComponentMemo={subHeaderComponentMemo}
        subHeader
        fixedHeader={true}
        fixedHeaderScrollHeight="calc(100vh - 17rem - 12px)"
      />
    </div>
  );
}
