import React from 'react';
import { useQuery } from '@apollo/client';
import Spinner from 'common/components/Spinner';
import { 
  GET_COMPANY,
} from 'apollo/queries';
import CompanyTeams from './CompanyTeams';
import './company.scss';

export default function Company({ match, dispatchErrorPage }) {

  const { loading, error, data } = useQuery(GET_COMPANY, {
    skip: !match,
    variables: { id: match?.params?.id },
  });

  if (!match) return null;
  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) {
    dispatchErrorPage({
      code: error.code
    })
    return null;
  }
  
  const { id, name, phone, address, vatNumber } = data.getCompany;
  
  return (
    <div className="Company bg-white">
      <div className="header bb b--pale-slate pa5">
        <h3>Perfil de la compañía: {name}</h3>
      </div>
      <div className="info pa5">
        { phone &&
          <div className="phone">
            <label>Phone:</label><span>{phone}</span>
          </div>
        }
        { address &&
          <div className="addres">
            <label>Address:</label><span>{address}</span>
          </div>
        }
        { vatNumber &&
          <div className="vatNumber">
            <label>VAT:</label><span>{vatNumber}</span>
          </div>
        }
      </div>
      <div className="pa5">
        <CompanyTeams companyId={id} />
      </div>
    </div>
  )
}