import * as Sentry from '@sentry/browser';
import { sentry } from 'config';
import { topDomain } from 'utils';

export default function SentryLoader() {
  if (sentry) {
    Sentry.init(sentry.init);
    Sentry.addBreadcrumb({
      category: 'topDomain',
      message: topDomain,
      level: 'info'
    });
  }
}

export const captureMessage = ({ store, level = 'warning', type, message = '' }) => {
  const user = store? store.getState().user : {};
  Sentry.configureScope((scope) => {
    scope.setLevel(level);
    scope.setUser({
      id: user.profile ? user.profile.uuid : user.distinct_id,
    });
    scope.setTag("language", window.i18n.language);
    if (type) scope.setTag("type", type);
  });
  Sentry.captureMessage(message);
}
