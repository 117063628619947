
import { withEffects } from 'redux/refractSideEffects';
import { pipe, map, merge } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import { CURRENT_ROUTE, errorPage as setErrorPage}from 'redux/actions';
import CompanyTeam from './CompanyTeam';

const aperture = ({ store }) => component => {
  const dispatchErrorPage = ({ code, message}) => {
    store.dispatch(setErrorPage({
      code,
      error: {
        message,
      }
    }))
  }

  return (
    pipesToProps(
      pipe(
        merge(
          component.mount,
          store.observe(CURRENT_ROUTE),
        ),
        map((effect) => {
          if (!effect) return null;
          return { route: effect.payload };
          // return { match };
        })
      ),
      pipe(
        component.mount,
        map(() => ({
          dispatchErrorPage,
        }))
      )
    )
  )
}

const handler = () => effect => null

export default withEffects(handler)(aperture)(CompanyTeam, 'CompanyTeamContainer');
