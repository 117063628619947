import { combineReducers } from 'redux';
import buildApiReducers from 'redux/buildApiReducers'
import * as custom from 'redux/reducers/_index'
import * as user from './user'
import * as routes from './routes';
import * as customizer from './customizer';
import * as errors from './errors';

const apiReducers = buildApiReducers();
const api = Object.keys(apiReducers).length === 0 ? {} : { api: combineReducers(apiReducers) };

export default combineReducers(Object.assign({},
  api,
  user,
  custom,
  routes,
  customizer,
  errors,
));
