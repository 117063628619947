
import { withEffects } from 'redux/refractSideEffects';
import Terms from './Terms';

const aperture = () => component => null

const handler = () => effect => {
  switch(effect.type) {
    default: return null
  }
}

export default withEffects(handler)(aperture)(Terms);
