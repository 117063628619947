import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { 
  GET_ROLES, GET_PERMISSIONS,
  ADD_ROLE, UPDATE_ROLE } from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import Toggle from 'common/components/Toggle';
import { Input } from 'common/components/Form';
import { toastSuccess } from 'dispatcher/toast';


export default function Roles () {
  const [selectedRole, setSelectedRole] = useState()
  const {
    loading: rolesLoading,
    error: rolesError,
    data: rolesData,
  } = useQuery(GET_ROLES);

  const {
    loading: permissionsLoading,
    error: permissionsError,
    data: permissionsData,
  } = useQuery(GET_PERMISSIONS);

  const [addRole] = useMutation(ADD_ROLE, {
    onCompleted: ({ addRole }) => {
      toastSuccess(`Rol ${addRole.name} añadido correctamente`)
      setSelectedRole(addRole)
    }
  });
  const [updateRole] = useMutation(UPDATE_ROLE, {
    onCompleted: ({ updateRole }) => {
      toastSuccess(`Rol ${updateRole.name} modificado correctamente`)
      setSelectedRole(updateRole)
    }
  });

  if (rolesLoading || permissionsLoading) return <Spinner className="w3 h3 absolute-center" />;
  if (rolesError || permissionsError) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }
  const roles = rolesData.getRoles;
  // const permissions = permissionsData.getPermissions.permissions;
  const hierarchy = permissionsData.getPermissions.hierarchy;
  if(!selectedRole) return setSelectedRole(roles[0]);

  const handleSelectRole = (role) => {
    return setSelectedRole(role);
  }
  const hasPermission = (role, permissionName) => {
    let userHasPermission = false;
    if(role.name === "Super Admin") return true;
    role.permissions.map((rolePermission) => {
      if(rolePermission.name === permissionName) {
        userHasPermission = true;
        return null;
      }
      return null;
    })
    return userHasPermission;
  }

  const handleAddRole = (e) => {
    e.preventDefault();
    addRole({
      variables: {
        input: {
          name: e.target.elements.addRoleName.value,
          permissions: []
        }
      },
      refetchQueries: [
        { query: GET_PERMISSIONS },
        { query: GET_ROLES },
      ]
    })
    e.target.elements.addRoleName.value = ''
  }

  const handleUpdateRole = (e, selectedRole, name, kind, action) => {
    console.log('UPDATE ROLE', selectedRole, name, kind, action)
  
    let currentPermissions = Array.from(selectedRole.permissions)
    const permissionToggled = {
      module: name,
      kind: kind,
      action: action.split(':')[1],
      name: action
    }
    let newPermissions;
    if(e.target.checked) {
      currentPermissions.push(permissionToggled)
      newPermissions = currentPermissions;
    } else {
      newPermissions = currentPermissions.filter((permission) => {
        return permission.action !== permissionToggled.action
      })
    }
    updateRole({
      variables: {
        id: selectedRole.id,
        input: {
          name: selectedRole.name,
          permissions: newPermissions
        }
      },
      refetchQueries: [
        { query: GET_PERMISSIONS },
        { query: GET_ROLES },
      ]
    })
  }

  return (
    <div className="flex flex-grow-1 h-100">
      <div className="br b--pale-slate pa5">
        <form className="flex items-center justify-between" onSubmit={handleAddRole}>
          <Input
            id="addRoleName"
            placeholder="Añadir nuevo rol"
            required
          />
          <button type="submit">+</button>
        </form>
        {roles.map((role) => <div 
            key={role.name}
            className={`pa3 pointer ${selectedRole.name === role.name ? 'bg-cherub' : ''}`}
            onClick={() => handleSelectRole(role)}>{role.name}
          </div>
        )}
      </div>
      <div className="flex-grow-1 bg-concrete">
        <form className="flex flex-wrap items-start flex-grow-1 pa5 flex-gap">
          {hierarchy.map(({ name, kinds }) => {
            const hasNoActions = !kinds[0]?.actions.length && !kinds[1]?.actions.length && !kinds[2]?.actions.length;
            return hasNoActions ? null : <div key={name} className="bg-white ba br2 b--pale-slate pa5 flex-grow-1" style={{width: '40%'}}>
              <div className="mb5 f3 flex items-center justify-start">
                {name}
              </div>
              {kinds.map((kind) => !kind.actions.length ? null : <div key={kind.name} className="pl5">
                  <div className="mb2 f4">{kind.name}</div>
                  <div className="flex flex-column items-start pl5">
                    {kind.actions.map((action) => {
                      return <div key={action.name} className="mb2"><Toggle
                          id={action.name}
                          key={action.name}
                          labelText={action.name.split(':')[1]}
                          checked={hasPermission(selectedRole, action.name)}
                          onChange={(e) => {
                            return handleUpdateRole(e, selectedRole, name, kind.name, action.name)
                          }}
                        /></div>
                    })}
                  </div>
                </div>
              )}
            </div>
          })}
          {/* {permissions.map((permission) => <Toggle 
              key={permission.action}
              labelText={permission.name}
              checked={hasPermission(selectedRole, permission.name)}
              onChange={() => {
                return console.log('ON_CHANGE')
              }}
            />
          )} */}
        </form>
      </div>
    </div>
  );

}