import React from 'react';
import Creatable from 'react-select/creatable';
import './MultiSelectCreatable.css';

const MultiSelectCreatableField = ({ value, id, placeholder, options, onChange, className, isValidNewOption }) => {
  const [selected, setSelected] = React.useState(value);

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions)
    onChange(selectedOptions);
  };

  return (
    <Creatable
      isMulti
      id={id}
      value={selected}
      onChange={handleChange}
      options={options}
      isValidNewOption={isValidNewOption}
      // clearable={true}
      // closeOnSelect={true}
      // removeSelected={true}
      className={`react-select items-center primary inline-flex flex-grow-1 ${className}`}
      placeholder={placeholder}
      classNamePrefix="react-select"
    />
  );
};

export default MultiSelectCreatableField;
