import React, { useState } from 'react';
import queryString from 'query-string';
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_USERS } from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import { showModal } from 'dispatcher/modal';
import { InviteMembers } from '../modals';
import Members from './Members';
import Invites from './Invites';

const TABS = {
  MEMBERS: 'members',
  INVITATIONS: 'invitations',
}


export default function CompanyTeamTabs({ route, team }) {

  const changeTab = (tab) => {
    route.history.replace({
      search: queryString.stringify({ tab })
    })
  }

  const { invites, members, id } = team;
  const { loading, error, data } = useQuery(GET_COMPANY_USERS, {
    skip: !route?.match,
    variables: { id: route?.match?.params?.companyId },
  });  
  
  const queryTab = queryString.parse(route.location.search)?.tab || null;
  const [activeTab, toggle] = useState(queryTab || TABS.MEMBERS);
  if (!queryTab || queryTab !== activeTab) {
    changeTab(activeTab);
  }
  

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) {
    return <div>error :)</div>
  }

  const { getCompanyUsers: companyUsers } = data;

  const openInviteMembersModal = () => {
    showModal({
      children: <InviteMembers teamId={team.id} members={members} companyUsers={companyUsers} />
    })
  }

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom tabs--justify mt5">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === TABS.MEMBERS })}
                  onClick={() => {
                    toggle(TABS.MEMBERS);
                  }}
                >
                  {t('Miembros')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === TABS.INVITATIONS })}
                  onClick={() => {
                    toggle(TABS.INVITATIONS);
                  }}
                >
                  {t('Invitaciones')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={TABS.MEMBERS}>
                <Members teamId={id} members={members} openInviteMembersModal={openInviteMembersModal} />
              </TabPane>
              <TabPane tabId={TABS.INVITATIONS}>
                <Invites teamId={id} invites={invites} openInviteMembersModal={openInviteMembersModal} />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
}
