
import { withEffects } from 'redux/refractSideEffects';
import { onRouteChange } from 'pipes/route';
import { pipe, map } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import CustomDataTable from './CustomDataTable';

const aperture = () => component => pipesToProps(
  pipe(
    onRouteChange(component),
    map(route => ({ route })),
  ),
)

const handler = () => effect => null

export default withEffects(handler)(aperture)(CustomDataTable, 'CustomDataTable');
