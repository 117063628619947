import React from 'react';
import Label from 'common/components/Form/Label';
import './Radio.css';

const RadioButton = React.forwardRef(
  function RadioButton(props, ref) {
    return (
      <div className="Form-group-radiobutton">
        <input ref={ref} {...props} type="radio" className={`RadioButton clip ${props.className || ''}`} />
        <Label id={props.id} label={props.label || 'Label'} className={props.labelClass}/>
      </div>
    );
  }
);

export default RadioButton;
