import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import getRoute, { root } from 'routes/nav';
import { ReactComponent as UnauthorizedLogo } from './unauthorized-lock.svg';

const classes = {
  link: "Button-outlined di ml5",
}

export default function Unauthorized() {
  return (
    <React.Fragment>
    <div className="center-ns flex flex-row mt8 mw-l-l mw-s w-auto">
      <div className="mh5">
        <p className="black f1 mb0">{t('statics:403:title')}</p>
        <p className="iron f2 mt1">{t('statics:403:description')}</p>
        <h1 className="iron f2 fw1">{t('statics:403:errorCode', { code: 401 })}</h1>
        <p className="iron f5">{t('statics:403:helpButtonsTitle')}</p>
        <Link className={classes.link} to={getRoute(root)}>{t('statics:403:home')}</Link>
      </div>
      <UnauthorizedLogo className="db-l dn ml8 mw-m pl8 self-end w-25" />
    </div>
    <Helmet>
      <meta name="render:status_code" content="401" />
    </Helmet>
  </React.Fragment>
  );
}
