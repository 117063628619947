import { withEffects } from 'redux/refractSideEffects';
import { map, throttle, pipe } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import { fromEvent } from 'dispatcher';
import { contentLoaded } from 'dispatcher/modal';
import { onRouteChange } from 'pipes/route';
import Modal from './Modal';

const aperture = () => component => {
  let modalType = undefined;

  return pipesToProps(
    pipe(
      fromEvent('MODAL_CLOSE'),
      throttle(500),
      map(event => {
        document.querySelector('body').classList.remove('scroll-lock');
        if (!event || !event.closeIfType || event.closeIfType === modalType) return ({ children: null })
      })
    ),
    pipe(
      fromEvent('MODAL_OPEN'),
      throttle(500),
      map(({ children, type, style, className, asyncContent }) => {
        document.querySelector('body').classList.add('scroll-lock');
        contentLoaded(false);
        modalType = type;
        return ({ children, style, className, asyncContent })
      })
    ),
    pipe(
      onRouteChange(component),
      map(() => {
        document.querySelector('body').classList.remove('scroll-lock');
        return { children: null }
      })
    ),
    pipe(
      fromEvent('CONTENT_LOADED'),
      map(contentLoaded => ({ contentLoaded }))
    )
  );
}

const handler = () => () => null;

export const ModalEffects = withEffects(handler)(aperture)(Modal, 'ModalEffects');
