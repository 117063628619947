import React, { useState, useEffect, useRef } from 'react';
import { flatten } from 'utils';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Label from 'common/components/Form/Label';
import Overlay from './Overlay';
import Weekday from './Weekday';
import Navbar from './Navbar';
import Caption from './Caption';
import './InputDate.css';

const validDate = date => {
  const value = new Date(date)
  if (isNaN(value.getTime())) return ''
  return value.toLocaleDateString('lt')
}

const hasNative = /(android|ios)/i.test(navigator.userAgent)

const InputDate = ({
  wrapperClassName, className, onChange,
  defaultValue, placeholder, forceOpenState,
  clearOpenState, header, yearRange, disabledDays,
  forceFixed, avoidNative = (!!disabledDays || !!header),
  dayPickerPosition, error, success, ...props
}) => {
  const useLib = !hasNative || avoidNative;
  const [date, setDate] = useState(validDate(defaultValue));
  const [changed, setChanged] = useState(false);
  const [size, setSize] = useState('short');
  const [overlay, setOverlay] = useState(null);
  const [shownMonth, setShownMonth] = useState(props.month);
  const hasError = error ? 'b--error' : '';
  const isSuccess = success ? 'b--success' : '';

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width >= 256) {
      setSize('long')
    } else {
      setSize('short')
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  useEffect(() => {
    if (changed && forceOpenState && clearOpenState) {
      clearOpenState()
    }
    if (changed && onChange) {
      if (ref.current.checkValidity()) {
        onChange({ target: ref.current })
      }
      setChanged(false)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed])

  const dateFormat = { month: size, day: '2-digit', year: 'numeric'};
  const formattedDate = date ? new Date(date).toLocaleDateString(i18n.language, dateFormat) : null;
  return (
    <div className={`relative ${wrapperClassName || ''}`}>
      {props.label && <Label id={props.id} label={props.label || 'Label'} className={props.labelClass}/>}
      <div className="DateInput-wrapper relative h-100">
        <input
          value={date}
          type="date"
          placeholder="yyyy-mm-dd"
          className={`DateSelect outline-0 ${className || ''} ${hasError} ${isSuccess} ${props.disabled ? 'v-hidden' : ''} ${useLib ? 'dn' : 'db'}`}
          onChange={e => { setDate(e.target.value); setChanged(true) }}
          ref={ref}
          {...props}
        />
        <div
          className={`DatePlaceholder DateSelect flex items-center justify-center top-0 inline truncate bg-theme-back pointer outline-0 ${useLib? 'relative' : 'pe-none absolute'} ${props.disabled ? 'pe-none theme-mid' : ''} ${hasError} ${isSuccess} ${className || ''}`}
          {...(useLib && {
            onClick: e => {
              e.preventDefault();
              e.stopPropagation();
              setOverlay(true)
            },
            tabIndex: -1,
          })}
        >
          {formattedDate || placeholder || ''}
        </div>
        {(useLib && (!!overlay || forceOpenState)) &&
          <Overlay
            placeholder={placeholder}
            close={() => { setChanged(true); setOverlay(null) }}
            forceFixed={forceFixed}
            customPosition={dayPickerPosition}
          >
            <React.Fragment>
              {header}
              <DayPicker
                {...(date && { selectedDays: new Date(date) })}
                onDayClick={(d, modifiers = {}) => {
                  if (modifiers.disabled && !modifiers.selected) {
                    return;
                  }
                  const value = d.toLocaleDateString('lt');
                  setDate(value)
                  setChanged(true)
                  setOverlay(null)
                }}
                {...((props.min || props.max || disabledDays) && {
                  disabledDays: flatten([{
                    ...(props.min && { before: new Date(props.min) }),
                    ...(props.max && { after: new Date(props.max) }),
                  }, disabledDays || []])
                })}
                {...((date || props.min) && {
                  month: new Date(date || props.min),
                })}
                weekdayElement={<Weekday />}
                navbarElement={<Navbar />}
                captionElement={<Caption yearRange={yearRange} onChange={setShownMonth} />}
                {...(shownMonth && {
                  month: shownMonth,
                })}
                fixedWeeks
              />
            </React.Fragment>
          </Overlay>
        }
      </div>
    </div>
  );
}

export default InputDate;
