import { generateHash, aes } from 'utils/crypto';

const SEED_LENGTH = 32;
const IV_LENGTH = 16;

export const encryptParams = (payload) => {

  if (!payload) {
    throw new Error('No payload to encrypt')
  }

  const randomSeed = generateHash(SEED_LENGTH, 'alphanumeric');
  const randomIV = generateHash(IV_LENGTH, 'alphanumeric');
  const encryptedPayload = aes.encrypt(JSON.stringify(payload), randomSeed, randomIV);

  return `${encryptedPayload}${randomSeed}${randomIV}`;
}

export const decryptParams = (hash) => {
  
  const seed = hash.substr(hash.length - (SEED_LENGTH + IV_LENGTH), SEED_LENGTH);
  const iv = hash.substr(hash.length - IV_LENGTH, IV_LENGTH);
  const encrypted = hash.substr(0, hash.length - SEED_LENGTH - IV_LENGTH)
  
  try {
    return JSON.parse(aes.decrypt(encrypted, seed, iv));
  } catch (e) {
    throw new Error(`Error decrypting params ${e.message}`);
  }
}