import { withEffects } from 'redux/refractSideEffects';
import { merge, pipe, map } from 'callbag';
import { CURRENT_ROUTE } from 'redux/actions';
import { pipesToProps } from 'pipes/toProps';
import { session as setSession } from 'redux/actions';
import Invite from './Invite';

const aperture = ({ store }) => component => {
  const dispatchSession = ({ session }) => {
    store.dispatch(setSession({ session }))
  }

  return merge(
    pipesToProps(
      pipe(
        merge(
          component.mount,
          store.observe(CURRENT_ROUTE),
        ),
        map((effect) => {
          if (!effect) return null;
          const { match, history } = effect.payload;
          return { match, history };
        })
      ),
      pipe(
        component.mount,
        map(() => ({
          dispatchSession,
        }))
      ),
    ),
    pipe(component.mount, map(() => ({type: 'MOUNT'}))),
  )
}

const handler = ({ store, route, match, history }) => effect => {
  switch (effect.type) {
    case 'MOUNT':
      
    break;
    default:
      return
  }
}

export default withEffects(handler)(aperture)(Invite, 'InviteContainer');
