import React, { useState } from 'react';
import { Input } from 'common/components/Form';
import { useQuery, useMutation } from '@apollo/client';
import Spinner from 'common/components/Spinner';
import './InputSearch.css';

export default function InputSearch ({ id, label, placeholder, selector, apiQuery, apiMutation, setDisabled }) {
  const [addElement] = useMutation(apiMutation,{
    onCompleted: ({ addIngredient }) => {
      const { id, name } = addIngredient
      setDisabled(false);
      setSelectedResult({
        id,
        name
      })
    },
    refetchQueries: [
        { query: apiQuery }
    ]
  });
  const [searchResponse, setSearchResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedResult, setSelectedResult] = useState(false);
  const { loading: apiLoading, error, data } = useQuery(apiQuery);

  if (apiLoading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }
  const { [apiQuery.definitions[0].name.value]: collection } = data;

  const handleSearch = async (e) => {
    const query = e.target.value;
    e.target.value = query;
    if(!query.length) {
      setSelectedResult(false)
      setDisabled(true);
    }
    if(query.length >= 1) {
      setLoading(true);
      try {
        const results = collection.filter(element => {
          return element[selector].toLowerCase().match(query.toLowerCase());
        })
        setSearchResponse(results);
      } catch(e) {
        setSearchResponse([]);
      }
      setLoading(false);
    }
  }

  const handleAddSubmit = (value) => {
    addElement({
      variables: {
        name: value,
      }
    });
  };
  return (
    <div className="relative" onBlur={() => setTimeout(() => setSearchResponse(false), 200)}>
      <Input 
        id={id}
        wrapperClass="w-100"
        loading={loading}
        label={label}
        placeholder={placeholder}
        defaultValue={selectedResult.name || ''}
        {...(selectedResult && { 'data-selected': selectedResult.id })}
        onChange={handleSearch}
        required={true}
      />
      {!!searchResponse && !!searchResponse.length && 
        <div className="absolute top-100 left-0 pv3 ph5 bg-white w-100 z-1 ba b--secondary br2 overflow-y-auto" style={{"maxHeight": '200px'}}>
          {searchResponse.map((result) => {
            return <div key={result.name} className="pv3 hover-bg-secondary pointer"
              onClick={(e) => {
                e.preventDefault();
                setSearchResponse(false)
                setDisabled(false);
                return setSelectedResult(result)
              }}
              >
              {`${result.name} - ${result.type}`}
            </div>})
          }
        </div>
      }
      {!!searchResponse && !searchResponse.length && 
        <div className="absolute top-100 left-0 pv3 ph5 bg-white w-100 z-1 ba b--secondary br2 overflow-y-auto" style={{"maxHeight": '200px'}}>
          <div 
            className="pv3 hover-bg-secondary pointer"
            onClick={(e) => {
              e.preventDefault();
              handleAddSubmit(document.getElementById(id).value)
              return setSearchResponse(false)
            }}
          >
            {`Añadir nuevo elemento: ${document.getElementById(id).value}`}
          </div>
        </div>
      }
    </div>
  );
}