import { withEffects } from 'redux/refractSideEffects';
import { map, pipe, merge } from 'callbag';
import { fromEvent as fromDispatcherEvent } from 'dispatcher';
import { pipesToProps } from 'pipes/toProps';
import { PROFILE } from 'redux/actions';
import SideBar from './SideBar';
import { onRouteChange } from 'pipes/route';

const aperture = ({ store }) => component => {

  return merge(
    pipe(component.mount, map(() => ({ type: 'MOUNT'}))),
    pipesToProps(
      pipe(
        fromDispatcherEvent('HEADER_FIXED_HEIGHT'),
        map(({ height }) => ({ height })),
      ),
      pipe(
        onRouteChange(component),
        map(route => ({ route })),
      ),
      pipe(
        merge(component.mount, store.observe(PROFILE)),
        map(() => store.getState().user),
        map(user => user ? user : { profile: null }),
        map(({ profile }) => ({ profile }))
      ),
    ),
  )
}

const handler = () => effect => {
  switch(effect.type) {
    case 'MOUNT':
    break;
    default: return null
  }
}

export default withEffects(handler)(aperture)(SideBar, 'SideBar');
