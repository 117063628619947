import * as allActions from 'redux/actions';

function buildApiReducer(actionTypes) {
  return (state = {}, action) => {
    switch (action.type) {
      case actionTypes.REQUEST: {
        return Object.assign({}, state, {
          isFetching: true,
          error: null,
        });
      }
      case actionTypes.SUCCESS: {
        return Object.assign({}, state, {
          isFetching: false,
          response: action.payload,
          error: null,
        });
      }
      case actionTypes.FAILURE: {
        return Object.assign({}, state, {
          isFetching: false,
          error: action.payload,
        });
      }
      case actionTypes.CLEAR: {
        return Object.assign({}, state, {
          isFetching: false,
          error: null,
        });
      }
  
      default: return state
    }
  }
}

/*
Autogenerate basic reducer for actions starting with API_
presumably generated with buildApiActions and therefore with
the same names for the reducer cases.
*/
export default function buildApiReducers() {
  const apiReducers = {}
  Object.keys(allActions)
    .filter(action => action.startsWith('API_'))
    .forEach(action => {
      apiReducers[action.substring(4).toLowerCase()] = buildApiReducer(allActions[action]);
    });
  return apiReducers;
}
