import React from 'react';
import { useLazyQuery } from '@apollo/client';
import Button from 'common/components/Button';
import { Input, InputPassword, Checkbox } from 'common/components/Form';
import { LOGIN } from 'apollo/queries';
import { LocalStorage } from 'utils';


export default function Login ({ logoSrc }) {

  const [login, { called, loading, data, error }] = useLazyQuery(LOGIN, {
    onCompleted: (res) => console.log("Data from query", res, called, data, error),
    onError: (err) => console.error("Error creating a post", err),
  });
  
  const classes = {
    layout_o: "w-auto mw-s center-ns bg-white  ph7 pb6 pt8",
    layout_i: "flex flex-column items-center",
    form: "w-100",
    submit: "w-100 mv7",
    link: "mb3 no-underline primary hover-secondary link nowrap",
    bold: "f4 fw5 mv5 tc",
    hr: "w-100 mv0 black-20 ba bb-0",
    or_wrapper: "relative mv6",
    or_text: "absolute absolute--center bg-white pa2",
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, rememberSession } = e.target.elements;
    LocalStorage.set('rememberSession', rememberSession.checked);
    login({ variables: {
      email: email.value,
      password: password.value,
    }})
  }

  return (
    <React.Fragment>
      <div className={`login ${classes.layout_o} ${classes.layout_i}`}>
        { logoSrc && <img src={logoSrc} className="mb8" alt="logo" /> }
        <form onSubmit={handleSubmit} className={classes.form}>
          <Input
            id="email"
            label={t('common:email')}
            required
            autoComplete="email"
            autoFocus
            className={classes.input}
            placeholder={t('common:placeholders:email')}
          />
          <InputPassword
            id="password"
            type="password"
            label={t('common:password')}
            required
            autoComplete="current-password"
            className={classes.input}
            placeholder={t('common:placeholders:password')}
          />
          <Checkbox
            id="rememberSession"
            label={t('common:remember-me')}
            defaultChecked
            className="mb3"
          />
          <Button
            type="submit"
            secondary
            className={classes.submit}
            loading={loading}
          >
            {t('common:login')}
          </Button>
        </form>
        {/* <Link className={`${classes.link} iron`} to={''}>{t('common:forgot')}</Link> */}
      </div>
    </React.Fragment>
  );

}