export const mergeConfigs = (COMMON_CONFIG, REACT_APP_NODE_ENV) => {

  console.info('[App] Config loaded for environment:', REACT_APP_NODE_ENV)
  if (!['production', 'testing', 'development'].includes(REACT_APP_NODE_ENV)) {
    throw new Error(`[App] Cannot load config with REACT_APP_NODE_ENV: ${REACT_APP_NODE_ENV}`)
  }

  let ENV_FULL_CONFIG;

  try {

    ENV_FULL_CONFIG = Object.assign({}, COMMON_CONFIG, require(`config/${REACT_APP_NODE_ENV}`));

    if (REACT_APP_NODE_ENV !== 'production') {
      console.log('[App] Config', ENV_FULL_CONFIG)
    }

    return ENV_FULL_CONFIG;

  } catch (e) {
    console.error(`[App] Error while loading config ${e.message}`, e);
  }
}