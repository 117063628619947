import React from 'react';
import { Select } from 'common/components/Form';
import './RecipeIceTypeSelector.css';

export default function RecipeIceTypeSelector ({ defaultValue, onChange, required = false }) {
  const iceTypes = [
    "Cubed",
    "Crushed",
    "None",
  ];

  return <Select 
    id="iceTypes"
    placeholder="Tipo de Hielo"
    label="Tipo de Hielo"
    defaultValue={defaultValue}
    onChange={onChange}
    required={required}
  >
    {iceTypes.map((iceType, index) =>
      <option key={index} value={iceType}>{iceType}</option>
    )}
  </Select>
}