export const apiBaseUrl = 'http://localhost:4000/';
export const websocketBaseUrl = 'ws://localhost:4000/graphql';
export const sso = {
  url: 'https://pre.accounts.mixole.com'
}
const cdnBase = 'https://statics.mixole.com';
export const cdn = {
  app: '/',
  images: `${cdnBase}/:scope/:type/:uuid/:name`,
}
