import { createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { refractEnhancer } from 'refract-redux-callbag'
import reducers from 'redux/reducers'
import { getInitialState } from './storage'

export default createStore(
  reducers,
  getInitialState(),
  composeWithDevTools(
    refractEnhancer()
  )
)
