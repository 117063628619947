const email = (event) => {
  // eslint-disable-next-line
  const isValid = event.target.value !== '' ? /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event.target.value) : true;
  if (!isValid) { event.target.focus(); } // This solve a bug on IOS getting out of sight the errored input in most cases.
  return isValid;
}

module.exports = {
  email,
}
