import React from 'react';
import { useQuery } from '@apollo/client';
import { Select } from 'common/components/Form';
import { GET_COMPANIES } from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import './CompanySelector.css';

export default function CompanySelector ({ defaultValue, onChange, required = false }) {
  const { loading, error, data } = useQuery(GET_COMPANIES);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { getCompanies: companies } = data;
  return <Select 
    id="companyId"
    placeholder="Select a company"
    label="Company"
    defaultValue={defaultValue}
    onChange={onChange}
    required={required}
  >
    {companies && companies.map((company, index) =>
      <option key={index} value={company.id}>{company?.name}</option>
    )}
  </Select>
}