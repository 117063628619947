import { CURRENT_ROUTE } from 'redux/actions';

export function current_route (state = {}, action) {
  switch (action.type) {
    case CURRENT_ROUTE:
      return Object.assign({}, state, {
        ...action.payload,
      });
    default: return state
  }
}
