import { gql } from '@apollo/client';

export const GET_RECIPE_CATEGORIES = gql`
  query {
    getRecipeCategories {
      id,
      name,
    }
  }
`;

export const UPDATE_RECIPE_CATEGORY = gql`
  mutation updateRecipeCategory($id: ID!, $input: RecipeCategoryInput) {
    updateRecipeCategory(id: $id, input: $input) {
      id,
      name,
    }
  }
`;

export const DELETE_RECIPE_CATEGORY = gql`
  mutation deleteRecipeCategory($id: ID!) {
    deleteRecipeCategory(id: $id)
  }
`;

export const ADD_RECIPE_CATEGORY = gql`
  mutation addRecipeCategory($input: RecipeCategoryInput) {
    addRecipeCategory(input: $input) {
      id,
      name,
    }
  }
`;