import React from 'react';
import Label from 'common/components/Form/Label';
import StatusMessage from 'common/components/Form/StatusMessage';
import './Select.css';

const Select = React.forwardRef(
  function Select(props, ref) {
    const hasError = props.error && props.error.status ? 'b--error' : '';
    const isSuccess = props.success && props.success.status ? 'b--success' : '';

    return (
      <div className={`Form-group ${props.wrapperClass || ''}`}>
        {props.label && <Label id={props.id} label={props.label} className={props.labelClass}/>}
        <div className="Select-wrapper">
          <select ref={ref}
            id={props.id}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
            value={props.value}
            required={props.required}
            placeholder={props.placeholder}
            disabled={props.disabled}
            name={props.name}
            className={`Select outline-0 ${hasError} ${isSuccess} ${props.className || ''}`}
          >
            {props.placeholder && <option value="">{props.placeholder}</option>}
            {props.children}
          </select>
        </div>
        <StatusMessage
          isSuccess={isSuccess}
          hasError={hasError}
          error={props.error}
          success={props.success}
          id={props.id}
        />
      </div>
    );
  }
);

export default Select;
