
import { toProps } from 'redux/refractSideEffects';
import { map, pipe, merge, scan } from 'callbag';

export const pipesToProps = (...sources) =>
  pipe(
    merge(...sources),
    scan((x, y) => Object.assign({}, x, y), {}),
    map(toProps)
  )
