import React from 'react';
import { useQuery } from '@apollo/client';
import { Select } from 'common/components/Form';
import { GET_USERS } from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import './UserSelector.css';

export default function UserSelector ({ defaultValue, onChange, required = false }) {
  const { loading, error, data } = useQuery(GET_USERS);
  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { users } = data;
  
  return (
    <Select
      id="userId"
      placeholder="Select an owner"
      label="Owner"
      defaultValue={defaultValue}
      onChange={onChange}
      required={required}
    >
      {users && users.map((user, index) =>
        <option key={index} value={user.id}>{user.username}</option>
      )}
    </Select>
  )
}