import React from 'react';
import Button from 'common/components/Button';
import { Select, Input, MultiSelect } from 'common/components/Form';
import { closeModal } from 'dispatcher/modal';
import { useQuery } from '@apollo/client';
import { GET_ROLES, GET_USERS } from 'apollo/queries';
import Spinner from 'common/components/Spinner';


export const UpdateModal = ({ row, updateUser }) => {

  const {
    loading: rolesLoading,
    error: rolesError,
    data: rolesData,
  } = useQuery(GET_ROLES);

  if (rolesLoading) return <Spinner className="w3 h3 absolute-center" />;
  if (rolesError) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }
  let selectedRoles;
  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    updateUser({
      variables: {
        id: e.target.id,
        data: {
          username: e.target.elements.username.value,
          email: e.target.elements.email.value,
          password: e.target.elements.password.value,
          active: JSON.parse(e.target.elements.active.value),
          roles: selectedRoles
        }
      },
      refetchQueries: [
          { query: GET_USERS }
      ]
    });
    closeModal();
  };

  const { username, email, id, active, roles: userRoles } = row;
  const roles = rolesData.getRoles;
  let multiSelectRoles = [], multiSelectUserRoles = [];
  roles.map(role => multiSelectRoles.push({
    value: role.id,
    label: role.name
  }))
  userRoles.map(role => multiSelectUserRoles.push({
    value: role.id,
    label: role.name
  }))
  return <form id={id} className="flex flex-column items-center justify-center pa8" onSubmit={handleUpdateSubmit}>
    <Input 
      id="username"
      type="username"
      label={t('common:username')}
      required
      defaultValue={username}
    />
    <Input 
      id="email"
      type="email"
      label={t('common:email')}
      required
      defaultValue={email}
    />
    <Input 
      id="password"
      type="password"
      label={t('common:password')}
      defaultValue={''}
    />
    <Select
      label={t('common:active')}
      id="active"
      required
      defaultValue={active}
    >
      <option value={true}>Active</option>
      <option value={false}>Disabled</option>
    </Select>
    <MultiSelect
      label={t('common:roles')}
      value={multiSelectUserRoles}
      id="userRoleSelect"
      onChange={(selectedElements) => {
        let rolesIDArray = [];
        if(selectedElements?.length) {
          selectedElements.map(element => {
            return rolesIDArray.push(element.value)
          });
        }
        selectedRoles = rolesIDArray
        return selectedElements;
      }}
      options={multiSelectRoles}
      placeholder="Selecciona un rol"
    />
    <Button
      type="submit"
      secondary
      className="center"
    >
      Save
    </Button>
  </form>
}