import React from 'react';
import { Select } from 'common/components/Form';
import Label from 'common/components/Form/Label';
import './Datalist.css';

const canUseDatalist = !!document.createElement('datalist').options;

const Datalist = React.forwardRef(
  function Datalist(props, ref) {
    if (!canUseDatalist) return Select({
      id: props.id, className:
      props.className,
      children: props.children,
      ...props
    });
    
    return !props.id ? 'Datalist needs id to be set' : (
      <div className="Form-group">
        <Label id={props.id} label={props.label} className={props.labelClass}/>
        <input
          ref={ref}
          id={props.id}
          list={`${props.id}-datalist`}
          name={`${props.id}-datalist`}
          className={`Datalist outline-0 ${props.className}`}
          {...props}
        />
        <datalist id={`${props.id}-datalist`} children={props.children} />
      </div>
    );
  }
); 

export default Datalist;
