import { ERROR_PAGE } from 'redux/actions';

export function errorPage(state = {}, action) {
  switch (action.type) {
    case ERROR_PAGE:
      return Object.assign({}, state, {
        ...action.payload,
      });
    default: return state
  }
}
