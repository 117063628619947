import { withEffects } from 'redux/refractSideEffects';
import Toggle from './Toggle';

const aperture = () => () => null

const handler = () => effect => {
  switch (effect.type) {
    default:
      return
  }
}
export default withEffects(handler)(aperture)(Toggle, 'Toggle');
