import store from 'redux/store';
import { 
  session as setSession,
  profile as setProfile
} from 'redux/actions';
import { client as apolloClient } from 'apollo';
import { PROFILE } from 'apollo/queries';
import getRoute, { login as loginRoute } from 'routes/nav';

export const login = async (data, { args }) => {
  if (!data) return data;
  store.dispatch(setSession({ session: data }));
  const { data: response } = await apolloClient.query({
    query: PROFILE,
  })
  store.dispatch(setProfile({ profile: response.profile }));
  return data;
}

export const logout = (data, { args }) => {
  store.dispatch(setSession({ session: null }));
  store.dispatch(setProfile({ profile: null }));
  store.getState().current_route.history.replace(getRoute(loginRoute))
  return data;
}

export const profile = async (data, { args }) => {
  if (!data) return data;
  store.dispatch(setProfile({ profile: data }));
  return data;
}
