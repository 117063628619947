import { withEffects } from 'redux/refractSideEffects';
import { getSession } from 'pipes/session';
import { getProfile } from 'pipes/profile';
import { pipesToProps } from 'pipes/toProps';
import Topbar from './Topbar';
import './topbar.css';

const aperture = ({ store }) => component => pipesToProps(
  getSession(component, store),
  getProfile(component, store),
)

const handler = () => () => null

export default withEffects(handler)(aperture)(Topbar, 'Topbar');
