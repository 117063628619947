
import { withEffects } from 'redux/refractSideEffects';
import { map, pipe } from 'callbag';
import { pipesToProps } from 'pipes/toProps';
import { onRouteChange } from 'pipes/route';
import { getProfile } from 'pipes/profile';
import Profile from './Profile';

const aperture = ({ store }) => component => {

  return pipesToProps(
    pipe(
      onRouteChange(component),
      map(route => ({ route })),
    ),
    getProfile(component, store),
  )

}

const handler = ({ history }) => effect => null;

export default withEffects(handler)(aperture)(Profile);
