import React from 'react';
import { showModal } from 'dispatcher/modal';
import { ReactComponent as BranchIcon } from '../svg/branch.svg';
import { ReactComponent as FingerPrintIcon } from '../svg/fingerprint.svg';
import { ReactComponent as InformationIcon } from '../svg/information.svg';

const [branch, hash, version] = process.env.REACT_APP_RELEASE.split(':');
const checksum = process.env.REACT_APP_DEPLOY_HASH;

const classes = {
  svg: 'svg-fill default h1 w2 ma0',
  releaseInfo: {
    p: 'flex items-center mt-2'
  }
}

const showAboutInfo = ({ name, description, logo }) => {
  return showModal({
    className:"flex items-center justify-center mt7 bg-white",
    children: (
      <div className="About-Modal">
        <div className="Logo flex flex-column items-center mb6">
          {logo && <img src={logo} className="h3 w-auto" alt="logo" />}
          <h4>{name}</h4>
        </div>
        <p className="Description mb6 w6 bb b--cherub pb6 text-center">{description}</p>
        <div className="ReleaseInfo mb6">
          <p className={classes.releaseInfo.p}><InformationIcon className={classes.svg} /> {version}</p>
          <p className={classes.releaseInfo.p}><BranchIcon className={classes.svg}/> {branch}:{hash}</p>
          <p className={classes.releaseInfo.p}><FingerPrintIcon className={classes.svg}/> {checksum} (MD5)</p>
        </div>
        <div className="Disclaimer text-center mb6">
          <p>En colaboración con <b>DigitalTack</b> {new Date().getFullYear()}</p>
          <a href={`https://www.digitaltack.com/?utm_source=${name.toLowerCase().replace(' ', '_')}&utm_content=about_modal`} target="_blank" rel="noopener noreferrer">
            www.digitaltack.com
          </a>
        </div>
      </div>
    )
  })
}

const InfoButton = ({ name, description, logo, className }) => {

  return (
    <div 
      className={`InfoButton w-100 h-100 flex items-center justify-content-center ${ className || '' }`}
      onClick={() => showAboutInfo({ name, description, logo })}
    >
      <span>
        {`${name} v${version}`}
      </span>
    </div>
  );
}

export default InfoButton;
