import React from 'react';
import { useQuery } from '@apollo/client';
import { Select } from 'common/components/Form';
import { GET_TECHNIQUES } from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import './RecipeTechniqueSelector.css';

export default function RecipeTechiniqueSelector ({ defaultValue, onChange, required = false }) {
  const { loading, error, data } = useQuery(GET_TECHNIQUES);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { getTechniques: techniques } = data;
  return <Select 
    id="techniques"
    placeholder="Técnica de la receta"
    label="Técnica de la receta"
    defaultValue={defaultValue}
    onChange={onChange}
    required={required}
  >
    {techniques && techniques.map((technique, index) =>
      <option key={index} value={technique.id}>{technique.name}</option>
    )}
  </Select>
}