import { gql } from '@apollo/client';

export const GET_TECHNIQUES = gql`
  query {
    getTechniques {
      id,
      name,
    }
  }
`;

export const UPDATE_TECHNIQUE = gql`
  mutation updateTechnique($id: ID!, $data: UpdateTechniqueInput) {
    updateTechnique(id: $id, data: $data) {
      id,
      name,
    }
  }
`;

export const DELETE_TECHNIQUE = gql`
  mutation deleteTechnique($id: ID!) {
    deleteTechnique(id: $id)
  }
`;

export const ADD_TECHNIQUE = gql`
  mutation createTechnique($name: String!) {
    createTechnique(name: $name) {
      id,
      name,
    }
  }
`;