
import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ACCEPT_INVITE, PROFILE } from 'apollo/queries';
import { Input, InputPassword, Checkbox } from 'common/components/Form';
import Button from 'common/components/Button';
import Spinner from 'common/components/Spinner';
import { toastSuccess, toastError } from 'dispatcher/toast';
import getRoute, { root, terms } from 'routes/nav';
import Anchor from 'components/Link/Anchor';
import { decryptParams } from 'utils/urls';
import './Invite.css';

const classes = {
  layout_o: "w-auto mw-s center-ns bg-white  ph7 pb6 pt8",
  layout_i: "flex flex-column items-center",
  form: "w-100",
  submit: "w-100 mv7",
  link: "mb3 no-underline primary hover-secondary link nowrap",
  bold: "f4 fw5 mv5 tc",
  hr: "w-100 mv0 black-20 ba bb-0",
  or_wrapper: "relative mv6",
  or_text: "absolute absolute--center bg-white pa2",
};

const Invite = ({ match, history, dispatchSession }) => {
  const logoSrc = '/images/logo/logo-cocktailapp.svg';
  const [getProfile] = useLazyQuery(PROFILE, {
    onCompleted: (res) => {
      return history.replace(getRoute(root));
    },
    onError: (err) => console.error("Error creating a post", err),
  })
  const [acceptInvite, {loading, error}] = useMutation(ACCEPT_INVITE, {
    onCompleted: ({ acceptInvite }) => {
      dispatchSession({ session: acceptInvite });
      getProfile();
      toastSuccess(`Invitación aceptada correctamente`)
    },
    onError: (err) => toastError('Error al aceptar la invitación')
  })
  if (!match || loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const { username, password, acceptTerms } = e.target.elements;
    acceptInvite({ variables: {
      teamId: inviteData.teamId,
      inviteHash: inviteData.inviteHash,
      data: {
        username: username?.value,
        password: password?.value,
        termsAccepted: acceptTerms?.checked
      }
    }})
  }

  const { inviteHash } = match.params;
  const inviteData = decryptParams(inviteHash);
  
  return (
    <div className="invite pa8 flex flex-column items-center justify-center">
      { logoSrc && <img src={logoSrc} alt="logo" className="mw-s"/> }
      <div className="flex flex-column items-center justify-center">
        <h3 className="mv8">{`${inviteData.ownerName} te ha invitado a unirte al equipo ${inviteData.teamName}`}</h3>
        <form onSubmit={handleSubmit} className={classes.form}>
          {JSON.parse(inviteData.newUser) && <Input
            id="username"
            label={t('Nombre de usuario')}
            required
            autoComplete="username"
            autoFocus
            className={classes.input}
            placeholder={t('Añade el nombre de usuario para la app')}
          />}
          {JSON.parse(inviteData.newUser) && <InputPassword
            id="password"
            type="password"
            label={t('Contraseña')}
            required
            autoComplete="current-password"
            className={classes.input}
            placeholder={t('Crea tu contraseña para iniciar sesion en la app')}
          />}
          {JSON.parse(inviteData.newUser) && <Checkbox
            id="acceptTerms"
            defaultChecked={false}
            className="mb3"
            label={
              <span>
                {t('Acepto los')}{' '}
                <Anchor newTab href={getRoute(terms)}>{t('Terminos y Condiciones')}</Anchor>
              </span>
            }
            required
          />}
          <Button
            type="submit"
            secondary
            className={classes.submit}
            loading={loading}
          >
            {t('Aceptar invitación')}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default Invite;