import React from 'react';
import { useQuery } from '@apollo/client';
import { Select } from 'common/components/Form';
import { GET_RECIPE_CATEGORIES } from 'apollo/queries';
import Spinner from 'common/components/Spinner';
import './RecipeCategorySelector.css';

export default function RecipeCategorySelector ({ defaultValue, onChange, required = false }) {
  const { loading, error, data } = useQuery(GET_RECIPE_CATEGORIES);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { getRecipeCategories: categories } = data;
  return <Select 
    id="category"
    placeholder="Categoria de la receta"
    label="Categoria de la receta"
    defaultValue={defaultValue}
    onChange={onChange}
    required={required}
  >
    {categories && categories.map((category, index) =>
      <option key={index} value={category.id}>{category.name}</option>
    )}
  </Select>
}