import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Spinner from 'common/components/Spinner';
import ProfileMain from './components/ProfileMain';
import ProfileTabs from './components/ProfileTabs';


const Profile = ({ profile }) => {
  if (!profile) return <Spinner className="w3 h3 absolute-center" />
  return (
    <Container>
      <div className="profile">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ProfileMain 
                profile={profile}
              />
            </Row>
          </Col>
          <ProfileTabs
            profile={profile}
          />
        </Row>
      </div>
    </Container>
  )
}

export default Profile;
