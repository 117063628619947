import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_GLASS_TYPES } from 'apollo/queries';
import { Select } from 'common/components/Form';
import Spinner from 'common/components/Spinner';
import './RecipeGlassTypeSelector.css';

export default function RecipeGlassTypeSelector ({ defaultValue, onChange, required = false }) {

  const { loading, error, data } = useQuery(GET_GLASS_TYPES);

  if (loading) return <Spinner className="w3 h3 absolute-center" />;
  if (error) { // need the status here
    // return <ErrorPage {...error}/>
    return <div>error :)</div>
  }

  const { getGlassTypes: types } = data;

  return <Select 
    id="glassTypes"
    placeholder="Tipo de Vaso"
    label="Tipo de Vaso"
    defaultValue={defaultValue}
    onChange={onChange}
    required={required}
  >
    { types.map((glassType, index) =>
      <option key={index} value={glassType.id}>{glassType.name}</option>
    ) }
  </Select>
}